import React from 'react';
import { withRouter } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import { Client as ConversationsClient } from '@twilio/conversations';
import CaseList from './CaseList/CaseList';
import EmailTemplates from './EmailTemplates';
import CaseDetails from './CaseDetails/CaseDetails';
import ChatList from './ChatList/ChatList';
import { isCustomerIdentity } from './ChatDetails/ChatUtils';
import './css/App.scss';
import useSet from './customHooks/useSet';
import $ from 'jquery';
import { version } from '../package.json';

// This represents the minimum width of the screen to put this into desktop mode
const minDesktopWidth = 1000;
const notificationAudio = new Audio('/alert.wav');
const ringAudio = new Audio('/ring.wav');
ringAudio.loop = true;

notificationAudio.volume = 0.5;
ringAudio.volume = 0.5;


function AppContainer(props) {
    const [selectedCase, setSelectedCase] = React.useState(props.selectedCase);
    const [selectedConversation, setSelectedConversation] = React.useState();
    const [conversations, addConversation, removeConversation, setConversations] = useSet([], conversation => conversation.sid, (a, b) => {
        // lastMessage is undefined for conversations with no messages
        return (b.lastMessage ? b.lastMessage.dateCreated : b.dateCreated) - (a.lastMessage ? a.lastMessage.dateCreated : a.dateCreated);
    });
    const [notificationMute, setNotificationMute] = React.useState(false);
    const setNotificationMuteWrapper = mute => {
        setNotificationMute(mute);
        if (mute) {
            notificationAudio.volume = 0;
            ringAudio.volume = 0;
        } else {
            notificationAudio.volume = 0.5;
            ringAudio.volume = 0.5;
        }
    };

    const [conversationsError, setConversationsError] = React.useState();
    const [conversationsUser, setConversationsUser] = React.useState();
    const [conversationsState, setConversationsState] = React.useState('connecting...');
    const [hasMoreConversations, setHasMoreConversations] = React.useState(false);
    const conversationsClient = React.useRef();
    const conversationsPaginator = React.useRef();
    const [conversationsToken, setConversationsToken] = React.useState();
    const [
        unassignedConverations,
        addUnassignedConversation,
        removeUnassingedConversation,
    ] = useSet([]);
    const [unreadEmailsCount, setUnreadEmailsCount] = React.useState(0);
    const [unreadChatsCount, setUnreadChatsCount] = React.useState(0);
    const [environment, setEnvironment] = React.useState('production');
    const [desktopMode, setDesktopMode] =
      React.useState(window.innerWidth >= minDesktopWidth);
    const [refreshCount, updateRefreshCount] = useRefreshCounter();
    const [itemDataList, setItemDataList] = React.useState([]);
    const [emailTemplates, setEmailTemplates] = React.useState([]);

    const conversationsErrorTimer = React.useRef();
    const logConversationsError = (message, error) => {
        // Set a timer to automatically clear the error
        if (conversationsErrorTimer.current) {
            clearTimeout(conversationsErrorTimer.current);
        }
        conversationsErrorTimer.current = setTimeout(() => {
            setConversationsError('');
        }, 10000);
        setConversationsError(message);
        console.error(error);
    };

    const setSelectedCaseWrapper = selected => {
        setSelectedConversation();
        setSelectedCase(selected);
    };

    const removeConversationWrapper = conversation => {
        removeUnassingedConversation(conversation.sid);
        removeConversation(conversation);
        setSelectedConversation();
    };

    const setSelectedConversationWrapper = selected => {
        if (selected) {
            $.get(`/api/conversations/convertConversationSid/${selected.sid}`)
                .done(result => {
                    setSelectedConversation(selected);
                    setSelectedCase(result.ID);
                    props.history.push('/case/' + result.ID);
                })
                .fail(error => {
                    console.error(`Could not retrieve the case: ${JSON.stringify(error)}`);
                    setSelectedCase();
                });
        } else {
            setSelectedConversation();
        }
    };

    React.useEffect(() => {
        $.get('/api/user/emailTemplates')
            .done(setEmailTemplates)
            .fail((error) => {
                console.error('Could not retrieve email templates: '
              + JSON.stringify(error));
            });
    }, []);

    React.useEffect(() => {
        $.get('/api/env/getEnv')
            .done((response) => {
                setEnvironment(response);
            })
            .fail((error) => {
                console.error('Could not retrieve the environment: '
              + JSON.stringify(error));
            });
    }, []);

    React.useEffect(() => {
        $.get('/api/item/allItemNumbers')
            .done((response) => {
                setItemDataList(response);
            })
            .fail((error) => {
                console.error(JSON.stringify(error));
            });
    }, []);

    React.useEffect(() => {
        const timer = setInterval(() => {
            const unreadTotal = unreadEmailsCount + unreadChatsCount;
            if (document.title === 'TST' && unreadTotal > 0) {
                document.title = unreadTotal + ' Unread messages';
            } else {
                document.title = 'TST';
            }
        }, 3000);
        return () => {
            clearInterval(timer);
            document.title = 'TST';
        };
    }, [unreadEmailsCount, unreadChatsCount]);

    React.useEffect(() => {
        if (!conversationsToken) {
            $.get('/api/conversations/token')
                .done(result => {
                    if (conversationsClient.current) {
                        conversationsClient.current.updateToken(result.token)
                            .catch(error => logConversationsError('Failed to update token', error));
                    } else {
                        ConversationsClient.create(result.token).then(async client => {
                            conversationsClient.current = client;

                            client
                                .on('messageAdded', message => {
                                    if (isCustomerIdentity(message.author)) {
                                        notificationAudio.play().catch(console.log);
                                    }
                                })
                                .on('connectionStateChanged', setConversationsState)
                                .on('conversationRemoved', removeConversationWrapper)
                                .on('conversationAdded', addConversation)
                                .on('conversationUpdated', ({ conversation }) => {
                                    if (conversation.lastMessage && isCustomerIdentity(conversation.createdBy) && !conversation.attributes.assignee) {
                                        addUnassignedConversation(conversation.sid);
                                    } else {
                                        removeUnassingedConversation(conversation.sid);
                                    }
                                    addConversation(conversation);
                                })
                                .on('tokenAboutToExpire', () => setConversationsToken())
                                .on('tokenExpired', () => setConversationsToken());

                            // Set user information
                            $.get('/api/user/username')
                                .then(username => {
                                    client.user.updateFriendlyName(username.split(' ')[0])
                                        .then(user => user.updateAttributes({
                                            isAvailable: result.defaultAvailability
                                        }))
                                        .then(setConversationsUser)
                                        .catch(error => {
                                            logConversationsError('Failed to update user information', error);
                                            setConversationsUser(client.user);
                                        });
                                }, error => {
                                    console.error('Unable to retrieve the current username: '
                                                  + JSON.stringify(error));
                                });

                            // Setup the conversations and paginator
                            conversationsPaginator.current = await client.getSubscribedConversations();
                            setHasMoreConversations(conversationsPaginator.current.hasPrevPage);
                        }, error => logConversationsError('Failed to connect to Twilio client', error));
                    }
                    setConversationsToken(result.token);
                });
        }
    }, [conversationsToken]);

    if (unassignedConverations.length) {
        ringAudio.play().catch(console.log);
    } else {
        ringAudio.pause();
    }

    window.addEventListener('resize', () => {
        clearTimeout(window.resizeFinished);
        window.resizeFinished = setTimeout(() => {
            setDesktopMode(window.innerWidth >= minDesktopWidth);
        }, 250);
    });

    const envHeader = desktopMode ? 'SANDBOX' : 'SB';

    const onLoadMoreConversations = async () => {
        conversationsPaginator.current = await conversationsPaginator.current.prevPage();
        setHasMoreConversations(conversationsPaginator.current.hasPrevPage);
        setConversations(conversationsPaginator.current.items.concat(conversations));
    };

    return (
        <div className="app">
            <header className="appHeader">;
                <img id="tektonLogo" src="/tekton-logo-hd.svg" alt="Tekton Logo" />
                {desktopMode ?
                    <div id="toolSolutionsHeader">
                        <h1>Tool Solutions</h1>
                        <span>v{version}</span>
                    </div>
                    : null }
                {environment === 'sandbox' ? <h1 id="envHeader">{envHeader}</h1> : null}
            </header>
            <Switch>
                <Route path="/templates" render={() =>
                    <EmailTemplates
                        emailTemplates={emailTemplates}
                        setEmailTemplates={setEmailTemplates}
                    />
                }/>
                <Route path="/" render={() =>
                    <div className="appBody">
                        <CaseList
                            selectedCase={selectedCase}
                            setSelectedCase={setSelectedCaseWrapper}
                            setUnreadEmailsCount={setUnreadEmailsCount}
                            useMobileLayout={!desktopMode}
                            refreshCount={refreshCount}
                            itemDataList={itemDataList}
                        />
                        <CaseDetails
                            key={selectedCase}
                            conversationsUser={conversationsUser}
                            selectedConversation={selectedConversation}
                            conversationsError={conversationsError}
                            emailTemplates={emailTemplates}
                            logConversationsError={logConversationsError}
                            setSelectedConversation={setSelectedConversation}
                            selectedCase={selectedCase}
                            useMobileLayout={!desktopMode}
                            setSelectedCase={setSelectedCaseWrapper}
                            updateRefreshCount={updateRefreshCount}
                            itemDataList={itemDataList}
                            environment={environment}
                            conversations={conversations}
                            removeConversation={removeConversationWrapper}
                        />
                        <ChatList
                            notificationMute={notificationMute}
                            setNotificationMute={setNotificationMuteWrapper}
                            setSelectedConversation={setSelectedConversationWrapper}
                            onLoadMoreConversations={onLoadMoreConversations}
                            hasMoreConversations={hasMoreConversations}
                            setUnreadChatsCount={setUnreadChatsCount}
                            conversationsState={conversationsState}
                            conversations={conversations}
                            conversationsUser={conversationsUser}
                            selectedConversation={selectedConversation}
                        />
                    </div>
                }/>
            </Switch>
        </div>
    );
}

export default withRouter(AppContainer);

const useRefreshCounter = () => {
    const [refreshCount, setRefreshCount] = React.useState(0);

    const updateRefreshCount = () => {
        setRefreshCount(refreshCount + 1);
    };

    return [refreshCount, updateRefreshCount];
};
