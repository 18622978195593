import React from 'react';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import useCollapsable from '../customHooks/useCollapsable';
import useOpenMenuEffect from '../customHooks/useOpenMenuEffect';
import '../css/CaseStatus.scss';
import ErrorPopUp from '../ErrorPopUp';
import NetSuitePropertyMap from '../NetSuitePropertyMap';

export default function CaseStatus(props) {
    const [caseStatus, setCaseStatus] = React.useState(props.status);
    const [collapsed, toggleCollapsed] = useCollapsable(true);
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);

    const mainOptionClickHandler = () => {
        toggleCollapsed();

        const menuOpenValue = !collapsed ? null : 'status';
        props.setOpenMenu(menuOpenValue);
    };

    useOpenMenuEffect(props.openMenu, 'status', collapsed, toggleCollapsed);

    React.useEffect( () => {
        setCaseStatus(props.status);
    }, [props.status]);

    const statusOptionClickHandler = (event) => {
    // Ensure that any closed case is confirmed by the user
        if (event.currentTarget.id === '5'
        && !window.confirm('Are you sure you want to close this case?')) {
            toggleCollapsed();
            return;
        }

        if (event.currentTarget.id === '10'
        && !props.problemItem
        ) {
            window.alert('The Waiting On Item status can be used only if there is a problem item on the case. \n\nPlease set a problem item.');
            toggleCollapsed();
            return;
        }

        setCaseStatus(event.currentTarget.id);
        toggleCollapsed();
        const requestBody = {
            caseId: props.selectedCase,
            status: event.currentTarget.id
        };
        $.ajax( {
            url: '/api/case/updateStatus',
            method: 'PUT',
            data: requestBody
        }).done(() => {
            props.updateCaseList();
            props.updateCaseDetails();
        }).fail( (error) => {
            console.error(error);
            setCaseStatus(caseStatus);
            setShowErrorMessage(true);
            setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
        });
    };

    const icon = collapsed ? faCaretDown : faCaretUp;

    return (
        <div id="caseStatusSelector">
            <ErrorPopUp showErrorMessage={showErrorMessage} />
            <span className="labelContainer">
                <label htmlFor="currentStatus">SET STATUS: </label>
                <span className="dropDownContainer">
                    <button
                        onClick={mainOptionClickHandler}
                        id="currentStatus"
                        className="statusOption"
                    >
                        {NetSuitePropertyMap.STATUS_MAP.get(caseStatus)}<FontAwesomeIcon id="dropDownIcon" icon={icon} />
                    </button>
                    <div className="optionContainer">
                        {renderAllStatusOptions(collapsed, statusOptionClickHandler)}
                    </div>
                </span>
            </span>
        </div>
    );
}

const renderAllStatusOptions = (collapsed, statusOptionClickHandler) => {
    if (collapsed) {
        return null;
    }

    return Array.from(NetSuitePropertyMap.STATUS_MAP)
        .map(([key, value]) => {
            return <button
                key={key}
                id={key}
                onClick={statusOptionClickHandler}
                className="statusOption"
            >
                {value}
            </button>;
        });
};
