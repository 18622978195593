import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import useCollapsable from '../customHooks/useCollapsable';
import '../css/ReplacementReasonDropDown.scss';
import NetSuitePropertyMap from '../NetSuitePropertyMap';

export default function ReplacementReasonDropDown(props) {
    const [selectedIssue, setSelectedIssue] = React.useState(props.defaultValue || '');
    const [displayOptions, toggleDisplayOptions] = useCollapsable(false);

    const selectedOptionClickHandler = () => {
        if (props.preventChanges) {
            return;
        }

        toggleDisplayOptions();
    };

    const optionClickHandler = (event) => {
        setSelectedIssue(event.currentTarget.id);
        props.setCaseIssue(event.currentTarget.id);
        toggleDisplayOptions();
    };

    // Set the current value if the props change
    React.useEffect(() => {
        if (props.caseIssue) {
            setSelectedIssue(props.caseIssue);
        }
    }, [props.caseIssue]);

    // Tracks changes to the default value if they are not provided upon componenet
    // initialization.
    React.useEffect(() => {
        if (props.defaultValue) {
            setSelectedIssue(props.defaultValue);
        }
    }, [props.defaultValue]);

    const icon = displayOptions ? faCaretUp : faCaretDown;

    const containerStyles = props.shrinkLabel ? {
        'justifyContent': 'flex-start',
        'marginLeft': '0',
        'paddingTop': '5px'
    } : {};

    const buttonStyles = {};
    if (props.requiresValue && !selectedIssue) {
        containerStyles['color'] = '#CC0000';
        buttonStyles['border'] = '1px solid #CC0000';
    }

    return (
        <div id="caseIssueSelector" style={containerStyles}>
            <span className="labelContainer" style={containerStyles}>
                <label htmlFor="selectedIssueOption" id="replacementReasonLabel">{props.shrinkLabel ? null : 'replacement '}reason: </label>
                <span className="dropDownContainer">
                    <button type="button" style={buttonStyles} className="caseIssueOption" id="selectedIssueOption" onClick={selectedOptionClickHandler}>
                        {NetSuitePropertyMap.REPLACEMENT_REASON_MAP[selectedIssue]}
                        <FontAwesomeIcon id="dropDownIcon" icon={icon} />
                    </button>
                    <div className="optionContainer" id="replacementReasonContainer">
                        {renderDropDownOptions(selectedIssue, displayOptions, optionClickHandler)}
                    </div>
                </span>
            </span>
        </div>
    );
}

const renderDropDownOptions = (currentSelection, displayOptions, optionClickHandler) => {
    if (!displayOptions) {
        return null;
    }

    const options = Object.entries(NetSuitePropertyMap.REPLACEMENT_REASON_MAP)
        .filter(option => option[0] !== currentSelection);

    if (currentSelection !== '') {
        options.unshift(['', '']);
    }

    return options.map(option =>
        <button type="button" className="caseIssueOption" id={option[0]} key={option[0]} onClick={optionClickHandler}>
            {option[1]}
        </button>
    );
};