import React from 'react';
import $ from 'jquery';
import '../css/AssigneeFilter.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import useCollapsable from '../customHooks/useCollapsable';
import useOpenMenuEffect from '../customHooks/useOpenMenuEffect';

const renderAllAssigneeOptions = (assigneeOptions, hideAllOptions,
    selectedAssigneeID, setSelectedAssignee,
    toggleCollapsed) => {
    if (hideAllOptions) {
        return null;
    }

    const assigneeOptionClickHandler = (event) => {
        setSelectedAssignee(event.currentTarget.id);
        toggleCollapsed();
    };

    return assigneeOptions.map( (assignee) => {
        if (parseInt(selectedAssigneeID) === parseInt(assignee.ID)) {
            return null;
        }

        return <button key={assignee.ID} id={assignee.ID} className="assigneeOption" onClick={assigneeOptionClickHandler}>{assignee.FirstName} {assignee.LastName}</button>;
    });
};

function AssigneeFilter(props) {
    const [assigneeOptions, setAssigneeOptions] = React.useState([]);
    const [collapsed, toggleCollapsed] = useCollapsable(true);

    useOpenMenuEffect(props.openMenu, 'assignee',
        collapsed, toggleCollapsed);
    React.useEffect(() => {
        if (assigneeOptions.length > 0) {
            return;
        }

        $.get('/api/assignee/list')
            .done( (response) => {
                setAssigneeOptions(response);
                if (response[0] && !props.currentAssignee && props.caseListComponent) {
                    props.setCurrentAssignee(response[0].ID);
                }
            })
            .fail( (error) => {
                console.error('Failed to retrieve assignees: '
              + JSON.stringify(error));
            });
    });

    const currentAssignee = assigneeOptions.find(
        (assignee) => parseInt(assignee.ID) === parseInt(props.currentAssignee) ) || {};

    const selectedAssigneeClickHandler = () => {
        toggleCollapsed();

        if (!props.caseListComponent) {
            const openMenuValue = !collapsed ? null : 'assignee';
            props.setOpenMenu(openMenuValue);
        }
    };

    const currentIcon = collapsed ? faCaretDown : faCaretUp;

    return (
        <div id="assigneeSelection">
            <span className="labelContainer">
                <label htmlFor="assigneeOption">ASSIGNED TO: </label>
                <span className="dropDownContainer">
                    <button
                        className="assigneeOption"
                        id="selectedAssignee"
                        onClick={selectedAssigneeClickHandler}
                    >
                        {currentAssignee.FirstName} {currentAssignee.LastName} <FontAwesomeIcon id="dropDownIcon" icon={currentIcon}/>
                    </button>
                    <div className="optionContainer">
                        {renderAllAssigneeOptions(assigneeOptions, collapsed,
                            props.currentAssignee, props.setCurrentAssignee, toggleCollapsed)}
                    </div>
                </span>
            </span>

        </div>
    );
}

export default AssigneeFilter;