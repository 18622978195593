import $ from 'jquery';
import Cookies from 'js-cookie';
import React from 'react';
import { Route, Router, Switch } from 'react-router';
import AppContainer from './AppContainer';
import history from './History';
import MediaViewer from './MediaViewer';

function App() {
    const token = Cookies.get('token');

    if (!token) {
        if (window.location.pathname.includes('media')) {
            window.location.href = '/auth/google?redirectUrl=' + encodeURIComponent(window.location.pathname);
        } else {
            window.location.href = '/auth/google';
        }
        return (
            <h1>Not logged in, redirecting...</h1>
        );
    }

    // This forces a page reload when the browser back button is pressed
    $(window).on('popstate', function () {
        window.location.reload(true);
    });

    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/case/:caseId" render={(props) => <AppContainer selectedCase={props.match.params.caseId} />} />
                <Route exact path="/media/s3/:s3File" render={(props) => <MediaViewer s3File={props.match.params.s3File} />} />
                <Route exact path="/media/:mediaSid" render={(props) => <MediaViewer mediaSid={props.match.params.mediaSid} />} />
                <Route path="/" render={() => <AppContainer />} />
            </Switch>
        </Router>
    );
}

export default App;
