import React from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLessThan, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import '../css/CaseDetails.scss';
import CaseProperties from './CaseProperties';
import CaseMessageList from './CaseMessageList';
import ChatDetails from '../ChatDetails/ChatDetails';
import CaseOrder from '../CaseOrder/CaseOrder.js';
import QuickEditProperties from './QuickEditProperties';
import MessageListLoading from './ MessageListLoading';
import $ from 'jquery';
import getBaseUrl from '../BaseUrl';
import { CancelableRequest } from '../Utils';

function CaseDetails(props) {
    const [caseProperties, setCaseProperties] = React.useState({});
    const [messageCount, setMessageCount] = React.useState(-1);
    const [shouldRenderMessages, setShouldRenderMessages] = React.useState(false);
    const [casePropsRefreshCount, setCasePropsRefreshCount] = React.useState(0);
    const itemDataList = props.itemDataList;
    const [hasPendingOrder, setHasPendingOrder] = React.useState(false);

    const updateCaseDetails = () => {
        setCasePropsRefreshCount(casePropsRefreshCount + 1);
    };

    React.useEffect(() => {
        let updateCaseDetailsTimeout;
        if (!props.selectedCase) {
            return;
        }
        const request = new CancelableRequest($.get('/api/case/properties/' + props.selectedCase))
            .then(response => {
                setCaseProperties(response);

                // If no N
                // after 15 seconds
                if (!response.NetSuiteID) {
                    updateCaseDetailsTimeout = setTimeout(() => updateCaseDetails(), 15000);
                }
            }, error => {
                console.error('Encountered error retrieving case properties: '
                              + JSON.stringify(error));
                setCaseProperties({error: 'Error retrieving properties'});
            });

        return () => {
            clearTimeout(updateCaseDetailsTimeout);
            request.cancel();
        };
    }, [props.selectedCase, casePropsRefreshCount]);


    React.useEffect(() => {
        props.conversations.forEach(conversation => {
            if (conversation.sid === caseProperties.ConversationSID
                && props.selectedConversation !== conversation
                && props.conversationsUser) {
                props.setSelectedConversation(conversation);
            }
        });

        // If the case properties, conversations, and conversations user are
        // all populated, then we can go ahead and render messages
        if (caseProperties && props.conversations && props.conversationsUser) {
            setShouldRenderMessages(true);
        }
    }, [caseProperties, props.conversations, props.conversationsUser]);

    if (!props.selectedCase) {
        if (props.useMobileLayout) {
            return null;
        }
        return (
            <span id="caseDetails">
                <h2>No case has been selected yet</h2>
            </span>
        );

    }

    const backButtonClickEvent = () => {
        props.history.push('/');
        props.setSelectedCase(null);
    };


    const renderBackButton = (useMobileLayout) => {
        if (!useMobileLayout) {
            return null;
        }

        const baseUrl = getBaseUrl(props.environment);

        return (
            <div className="backToCaseList">
                <h3 id="backText" onClick={backButtonClickEvent}>
                    <FontAwesomeIcon icon={faLessThan} /> CASE LIST
                </h3>
                <a href={baseUrl + '/app/crm/support/supportcase.nl?id=' + caseProperties.NetSuiteID + '&whence='} className="externalLink" target="_blank" rel="noopener noreferrer">
                    <h3>{caseProperties.NetSuiteID} <FontAwesomeIcon icon={faExternalLinkAlt} /></h3>
                </a>
            </div>
        );
    };

    const updateCase = () => {
        props.setSelectedCase();
        props.setSelectedCase(props.selectedCase);
        props.setSelectedConversation(props.selectedConversation);
    };

    const renderMessages = () => {
        if (!shouldRenderMessages) {
            return <MessageListLoading />;
        }

        return props.selectedConversation ?
            <ChatDetails
                caseId={props.selectedCase}
                conversationsUser={props.conversationsUser}
                selectedConversation={props.selectedConversation}
                conversationsError={props.conversationsError}
                logConversationsError={props.logConversationsError}
                removeConversation={props.removeConversation}
                updateCase={updateCase}
            /> :
            <CaseMessageList
                selectedCase={props.selectedCase}
                selectedCaseNetSuiteID={caseProperties.NetSuiteID}
                emailTemplates={props.emailTemplates}
                updateRefreshCount={props.updateRefreshCount}
                updateCaseDetails={updateCaseDetails}
                updateCaseDetailsCount={casePropsRefreshCount}
                environment={props.environment}
                conversationSid={caseProperties.ConversationSID}
                setMessageCount={setMessageCount}
            />;
    };

    return (
        <div id="selectedCaseContainer">
            <QuickEditProperties
                caseId={props.selectedCase}
                caseProps={caseProperties}
                updateCaseList={props.updateRefreshCount}
                updateCaseDetails={updateCaseDetails}
                environment={props.environment}
                useMobileLayout={props.useMobileLayout}
                hasPendingOrder={hasPendingOrder}
                messageCount={messageCount}
            />
            <div id="caseDetails">
                {renderBackButton(props.useMobileLayout)}
                <CaseProperties
                    selectedCase={props.selectedCase}
                    useMobileLayout={props.useMobileLayout}
                    isConversation={props.selectedConversation}
                    updateRefreshCount={updateCaseDetails}
                    updateCaseList={props.updateRefreshCount}
                    caseProperties={caseProperties}
                    itemDataList={itemDataList}
                    environment={props.environment}
                    setSelectedCase={props.setSelectedCase}
                />
                {renderMessages()}
                <CaseOrder
                    selectedCase={props.selectedCase}
                    itemDataList={itemDataList}
                    caseProperties={caseProperties}
                    updateRefreshCount={updateCaseDetails}
                    hasPendingOrder={hasPendingOrder}
                    setHasPendingOrder={setHasPendingOrder}
                />
            </div>
        </div>
    );
}

export default withRouter(CaseDetails);
