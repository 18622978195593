import React from 'react';
import $ from 'jquery';
import SenderIcon from '../CaseDetails/SenderIcon';
import LoadingIndicator from '../LoadingIndicator';
import formatDate, { sameDay } from '../DateFormatter';

export default function ChatMessage({
    message,
    conversationsUser,
    participantNames,
    updateCase,
    caseId
}) {
    const [messageTimeElapsed, setMessageTimeElapsed] = React.useState(formatDate(message.dateCreated));
    const [contentTemporaryUrl, setContentTemporaryUrl] = React.useState('');
    const [processing, setProcessing] = React.useState(false);

    React.useEffect(() => {
        if (message.media) {
            message.media.getContentTemporaryUrl().then(setContentTemporaryUrl);
        }

        const today = new Date();
        const sentDate = new Date(message.dateCreated);

        // Only update the time automatically if the message was sent within the past hour
        if (sameDay(today, sentDate) && today.getHours() - sentDate.getHours() === 0) {
            const timer = setInterval(() => {
                setMessageTimeElapsed(formatDate(message.dateCreated));
            }, 60000);
            return () => {
                clearInterval(timer);
            };
        }

    }, []);

    const onSelectAddress = e => {
        e.preventDefault();

        const address = message.attributes.address;
        setProcessing(true);
        $.ajax({
            url: '/api/case/updateAddressForChat',
            method: 'PUT',
            data: {
                addressLine1: address.addr1,
                addressLine2: address.addr2 || '',
                city: address.city,
                state: address.state,
                country: address.country,
                zipCode: address.zip,
                caseId: caseId
            }
        })
            .done(updateCase)
            .fail((error) => {
                console.error('Unable to update problem item number: ' + JSON.stringify(error));
                setProcessing(false);
            });
    };


    return (
        <div key={message.sid} id="messageContainer" style={{ 'borderBottom': '1px solid lightgray' }}>
            <div className="individualMessage">
                <div className="flagContainer">
                    <p id="timeSent">{messageTimeElapsed}</p>
                </div>
                <div className="senderAndTimeContainer">
                    <SenderIcon
                        senderName={message.author}
                        useMessageStyles={true}
                        isCustomerMessage={message.author !== conversationsUser.identity}
                    />
                    <h5 id="messagePreviewName">{participantNames[message.author] || message.author}</h5>
                </div>
                {message.media ?
                    <div>
                        <div className="individualMessageContent">{message.media.filename} ({message.media.contentType})</div>
                        {contentTemporaryUrl ?
                            <div id="attachments">
                                <a
                                    href={contentTemporaryUrl}
                                    target='_blank'
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        alt="Click to preview"
                                        src={contentTemporaryUrl}
                                        className="attachmentChatImage"
                                    />
                                </a>
                            </div> :
                            <div className="loadingChatImage">
                                <LoadingIndicator/>
                            </div>
                        }
                    </div> :
                    <div className="individualMessageContent">
                        {message.attributes.subtype === 'address' ?
                            <>
                                <div>{message.body}</div>
                                <br/>
                                {message.attributes.address.complete ?
                                    <div>
                                        {renderAddress(message.attributes.address)}
                                        <button
                                            disabled={processing}
                                            className="secondaryButton"
                                            onClick={onSelectAddress}
                                        >
                                            USE ADDRESS
                                        </button>
                                    </div> :
                                    <small>Pending user response</small>
                                }
                            </> :
                            <>
                                {message.body}
                            </>
                        }
                    </div>
                }
            </div>
        </div>
    );
}

function renderAddress({
    addr1,
    addr2,
    city,
    state,
    country,
    zip
}) {
    return (
        <div>
            <div>{addr1}</div>
            {addr2 ? <div>{addr2}</div> : null}
            <div>{city}, {state}, {zip}</div>
            <div>{country}</div>
        </div>
    );
}
