import React from 'react';
import $ from 'jquery';
import '../css/ChatDetails.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faChevronCircleUp, faFileImage} from '@fortawesome/free-solid-svg-icons';
import { addConversationListeners, isCustomerIdentity, isSupportIdentity } from './ChatUtils';
import ChatMessage from './ChatMessage';
import { CancelableRequest } from '../Utils';
import useCollapsable from '../customHooks/useCollapsable';
import useSet from '../customHooks/useSet';
import useThrottledEffect from '../customHooks/useThrottledEffect';
import SenderIcon from '../CaseDetails/SenderIcon';

export default function ChatDetails({
    selectedConversation,
    conversationsUser,
    caseId,
    removeConversation,
    conversationsError,
    updateCase,
    logConversationsError
}) {
    const [collapsed, toggleCollapsed] = useCollapsable(false);
    const [typingParticipants, addTypingParticipant, removeTypingParticipant] = useSet([], participant => participant.sid);
    const [participantNames, setParticipantNames] = React.useState({});
    const [messages, addMessage, removeMessage, setMessages] = useSet(
        [],
        message => message.sid
    );
    const [imagePreviews, setImagePreviews] = React.useState([]);
    const [hasMoreMessages, setHasMoreMessages] = React.useState(false);
    const [newMessage, setNewMessage] = React.useState(sessionStorage.getItem(selectedConversation.sid) || '');
    const [sendingMessage, setSendingMessage] = React.useState(false);
    const [isRequestingAddress, setIsRequestingAddress] = React.useState(false);

    const messagePaginator = React.useRef({});

    const scrollToBottom = () => $('#chatMessageList').scrollTop($('#chatMessageList')[0].scrollHeight);

    const updateConversationAssignee = () => {
        if (selectedConversation.attributes.assignee !== conversationsUser.identity) {
            selectedConversation.updateAttributes({
                ...selectedConversation.attributes,
                assignee: conversationsUser.identity
            });
        }
    };

    useThrottledEffect(() => {
        if (newMessage.trim()) {
            selectedConversation.typing();
            updateConversationAssignee();
        }
    }, 2000, [newMessage]);

    React.useEffect(() => {
        const participantsRequest = new CancelableRequest(selectedConversation.getParticipants())
            .then(participants => {
                Promise.all(participants.map(async participant => {
                    if (isCustomerIdentity(participant.identity)) {
                        return await participant.getUser();
                    }
                    return {
                        friendlyName: participant.identity.replace(/^(\w)(\w*).*$/i, (match, firstLetter, name) =>
                            `${firstLetter.toUpperCase()}${name}`
                        ),
                        identity: participant.identity
                    };
                }))
                    .then(users => users.reduce((data, user) => {
                        data[user.identity] = user.friendlyName || user.identity;
                        return data;
                    }, {}))
                    .then(setParticipantNames)
                    .catch(error => logConversationsError('Failed to load conversation participant names', error));
            }, error => {
                logConversationsError('Failed to load participant names', error);
            });
        const messagesRequest = new CancelableRequest(selectedConversation.getMessages())
            .then(paginator => {
                messagePaginator.current = paginator;
                setHasMoreMessages(paginator.hasPrevPage);
                setMessages(paginator.items);
                scrollToBottom();
            }, error => logConversationsError('Failed to load conversation messages', error));

        const removeListeners = addConversationListeners(selectedConversation, [
            {
                event: 'messageAdded',
                callback: message => {
                    addMessage(message);
                    scrollToBottom();
                }
            },
            {
                event: 'messageUpdated',
                callback: ({ message }) => {
                    addMessage(message);
                    scrollToBottom();
                }
            },
            {
                event: 'messageRemoved',
                callback: message => {
                    removeMessage(message);
                }
            },
            {
                event: 'typingStarted',
                callback: addTypingParticipant
            },
            {
                event: 'typingEnded',
                callback: removeTypingParticipant
            },
            {
                event: 'participantJoined',
                callback: participant => addMessage({
                    attributes: {
                        type: 'alert'
                    },
                    body: `${participant.identity} joined`,
                    sid: `joined-${participant.identity}`
                })
            },
            {
                event: 'participantLeft',
                callback: participant => setMessages({
                    attributes: {
                        type: 'alert'
                    },
                    body: `${participant.identity} left`,
                    sid: `left-${participant.identity}`
                })
            }
        ]);
        return () => {
            participantsRequest.cancel();
            messagesRequest.cancel();
            removeListeners();
        };
    }, [selectedConversation.sid]);

    const onRequestAddress = e => {
        e.preventDefault();

        setNewMessage(isRequestingAddress ? '' : 'Could you please send us your address?');
        setIsRequestingAddress(!isRequestingAddress);
    };

    const onSendMessage = e => {
        if (e) {
            e.preventDefault();
        }

        setSendingMessage(true);
        const promises = [];
        const hasMessage = newMessage.trim();
        const hasImages = imagePreviews.length;

        if (isRequestingAddress) {
            promises.push(
                selectedConversation.sendMessage(newMessage, {
                    subtype: 'address',
                    address: {
                        complete: false
                    }
                })
                    .then(() => {
                        setNewMessage('');
                        setIsRequestingAddress(false);
                    })
                    .catch(error => logConversationsError('Failed to send address request', error))
            );
        } else {
            if (hasMessage) {
                sessionStorage.setItem(selectedConversation.sid, newMessage);
                promises.push(
                    selectedConversation.sendMessage(newMessage)
                        .then(() => {
                            setNewMessage('');
                            sessionStorage.removeItem(selectedConversation.sid);
                        })
                        .catch(error => logConversationsError('Failed to send message', error))
                );
            }

            if (hasImages) {
                promises.push(
                    Promise.all(imagePreviews.map(function (imagePreview) {
                        var formData = new FormData();
                        formData.append('file', imagePreview.data);
                        return selectedConversation.sendMessage(formData)
                            .catch(error => logConversationsError('Failed to send image', error));
                    }))
                        .then(() => setImagePreviews([]))
                );
            }
        }



        // If something is actually being sent
        // Update the assignee
        if (hasMessage || hasImages) {
            updateConversationAssignee();

            // Assign the case to the most recent person to respond
            promises.push($.ajax({
                url: '/api/assignee/update/case/' + caseId,
                method: 'PUT'
            }).fail( (error) => {
                console.error(error);
            }));
        }

        Promise.all(promises).finally(() => setSendingMessage(false));
    };
    const onNewMessageChange = e => {
        setNewMessage(e.currentTarget.value);
    };
    const onLoadMoreMessages = async () => {
        messagePaginator.current = await messagePaginator.current.prevPage();
        setHasMoreMessages(messagePaginator.current.hasPrevPage);
        setMessages(oldMessages => [ ...messagePaginator.current.items, ...oldMessages ]);
    };
    const onLeaveConversation = async () => {
        if (window.confirm('Leave Chat: you will receive no further updates on this chat, are you sure you want to proceed?')) {
            try {
                removeConversation(selectedConversation);
                await selectedConversation.leave();
            } catch (error) {
                logConversationsError('Failed to leave conversation', error);
            }
        }
    };
    const onEndConversation = () => {
        if (window.confirm('End Chat: this will end the chat permanently, are you sure you want to proceed?')) {
            $.post('/api/conversations/endConversation/' + selectedConversation.sid)
                .fail(error => logConversationsError('Error occurred when ending conversation', error));
        }
    };

    const onRemoveImage = name => () => {
        setImagePreviews(imagePreviews.filter(image => image.data.name !== name));
    };

    const onImageUpload = e => {
        const imageFiles = e.target.files;
        new Promise((resolve) => {
            const images = [];
            const reader = new FileReader();
            let currentImageIndex = 0;
            reader.readAsDataURL(imageFiles[currentImageIndex++]);

            reader.addEventListener('load', () => {
                images.push({
                    data: imageFiles[currentImageIndex - 1],
                    url: reader.result
                });
                if (currentImageIndex === imageFiles.length) {
                    resolve(images);
                } else {
                    reader.readAsDataURL(imageFiles[currentImageIndex++]);
                }
            });
        }).then(setImagePreviews);
    };

    // Freeze the text area when another support (or end user with @tekton.email) user is typing
    const isSupportTyping = typingParticipants.filter(participant => {
        return isSupportIdentity(participant.identity);
    }).length;

    return (
        <div className="caseMessageList">
            <div className="messageListHeader">
                <FontAwesomeIcon
                    id="collapseButton"
                    icon={collapsed ? faChevronCircleDown : faChevronCircleUp}
                    onClick={toggleCollapsed}
                />
                <h3 id="messageHeader">Messages</h3>
                <b className="chatErrorMessage">{conversationsError}</b>
                <div className="chatActionButtons">
                    <button className="leaveChatButton" onClick={onEndConversation}>END CHAT</button>
                    <button className="leaveChatButton" onClick={onLeaveConversation}>LEAVE</button>
                </div>
            </div>
            {collapsed ? null :
                <div>
                    <div id="messageListContent">
                        <div id="chatMessageList">
                            {hasMoreMessages ? <button onClick={onLoadMoreMessages} className="loadMoreMessages">Load more</button> : null}
                            {messages.map((message, index) => message.attributes.type === 'alert' ?
                                <div className="alertMessage" key={index}>{message.body}</div> :
                                <ChatMessage
                                    key={message.sid}
                                    message={message}
                                    caseId={caseId}
                                    participantNames={participantNames}
                                    conversationsUser={conversationsUser}
                                    updateCase={updateCase}
                                />
                            )}
                        </div>
                        {imagePreviews.length ?
                            <div className="chatImagePreviewsContainer">
                                {imagePreviews.map((image, index) =>
                                    <div key={index} className="chatImagePreview" >

                                        <button onClick={onRemoveImage(image.data.name)}>X</button>
                                        <img src={image.url}/>
                                    </div>
                                )}
                            </div> : null}
                        <div className="newMessageContainer">
                            <SenderIcon
                                id="newMessageSenderIcon"
                                senderName={conversationsUser.friendlyName || conversationsUser.identity}
                                useMessageStyles={true}
                                isCustomerMessage={false}
                            />
                            <form className="newChatMessageForm" onSubmit={onSendMessage}>
                                <textarea id="newChatMessageTextArea" placeholder="Type your message here..." value={newMessage} onChange={onNewMessageChange} disabled={isSupportTyping}/>

                                <label htmlFor="imageUpload">
                                    <input id="imageUpload" type="file" accept="image/*" multiple onChange={onImageUpload}/>
                                    <FontAwesomeIcon
                                        id="imageUploadIcon"
                                        icon={faFileImage}
                                    />
                                </label>
                                <button
                                    onClick={onRequestAddress}
                                    disabled={sendingMessage}
                                    className="secondaryButton"
                                >
                                    {isRequestingAddress ? 'CANCEL' : 'REQUEST ADDRESS'}
                                </button>
                                <button
                                    disabled={sendingMessage}
                                    className="messageButton"
                                    id="sendMessageExternal"
                                    type="submit"
                                >
                                    {isRequestingAddress ? 'REQUEST' : 'SEND'}
                                </button>
                            </form>

                            <div className="typingStatus">
                                {typingParticipants.map((participant, index) =>
                                    <span key={participant.sid}>{index ? ', ' : ''}{participantNames[participant.identity]}</span>
                                )}
                                {typingParticipants.length ? <span> is typing</span> : null}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
