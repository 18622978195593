import $ from 'jquery';
import React from 'react';
import '../css/CaseOrder.scss';


const emptyItemDetails = {
    Description: '',
    Inventory: '',
    Nickname: '',
    ImageUrl: '',
};

function CaseOrderItem(props) {
    const [itemDataList, setItemDataList] = React.useState([]);
    const [itemDetails, setItemDetails] = React.useState(emptyItemDetails);
    const [itemImage, setItemImage] = React.useState();
    const [itemUrl, setItemUrl] = React.useState();
    const [getLiveInventory, setGetLiveInventory] = React.useState(false);
    const [alternateItems, setAlternateItems] = React.useState([]);


    React.useEffect(() => {
        $.get('/api/item/orderItemNumbers')
            .done((response) => {
                setItemDataList(response);
            });
    }, []);

    const options = React.useMemo(() => {
        return itemDataList.map(item => <option key={item}>{item}</option>);
    }, [itemDataList]);

    React.useEffect(() => {
        if(props.replacementItem.ItemNumber) {
            $.get('/api/item/orderItemDetails/' + props.replacementItem.ItemNumber)
                .done((response) => {
                    if (response[0]) {
                        setItemDetails(response[0]);

                        if (response[0].DaysInSupply <= 2) {
                            setGetLiveInventory(true);
                        }
                    }
                })
                .fail((error) => {
                    console.error(JSON.stringify(error));
                });
            $.get('/api/item/alternateReplacementItemList/' + props.replacementItem.ItemNumber)
                .done((response) => {
                    if (response) {
                        setAlternateItems(response);
                        // alternateItems = response;
                    }else{
                        setAlternateItems([]);
                        console.log('there was no response body. alternateItems = ' + alternateItems);
                        // alternateItems = [];
                    }

                })
                .fail((error) => {
                    console.error(JSON.stringify(error));
                });
        }else{
            setItemDetails(emptyItemDetails);
            setAlternateItems([]);
            props.onItemNumberChangeHandler('', props.replacementIndex);
            props.onItemQtyChangeHandler('', props.replacementIndex);
            props.onItemIDChangeHandler('', props.replacementIndex);

        }

    }, [props.replacementItem.ItemNumber]);

    React.useEffect(() => {
        if(itemDetails.Nickname) {
            setItemImage( 'https://www.tekton.com/images/product/' + itemDetails.Nickname + '_1.jpg' );
            setItemUrl( 'https://www.tekton.com/' + itemDetails.UrlComponent );
            if(itemDetails.Inventory > 0) {
                props.onItemQtyChangeHandler(1, props.replacementIndex);
            }
            props.onItemIDChangeHandler(itemDetails.ID, props.replacementIndex);
        }else{
            setItemImage('');
            setItemUrl('');
        }
    }, [itemDetails]);

    // Get live inventory calculation every time the item is under 2 days supply
    React.useEffect(() => {
        if (!getLiveInventory) {
            return;
        }

        $.get('/api/item/liveInventory/' + itemDetails.NetSuiteID)
            .done((response) => {
                if (response.success) {
                    const newItemDetails = {...itemDetails};
                    newItemDetails.Inventory = response.inventory;
                    newItemDetails.NextInventoryDate = response.nextInventoryDate;
                    setItemDetails(newItemDetails);
                } else {
                    console.error('Error retrieving live inventory: ' + response.message);
                    const newItemDetails = {...itemDetails};
                    newItemDetails.Inventory = 0;
                    setItemDetails(newItemDetails);
                }
            })
            .fail((error) => {
                console.error('Error retrieving live inventory: ' + JSON.stringify(error));
                const newItemDetails = {...itemDetails};
                newItemDetails.Inventory = 0;
                setItemDetails(newItemDetails);
            });

        setGetLiveInventory(false);
    }, [itemDetails, getLiveInventory]);


    const itemNumberChangeHandler = (event) => {
        event.preventDefault();
        props.onItemNumberChangeHandler(event.currentTarget.value, props.replacementIndex);
    };

    const itemQtyChangeHandler = (event) => {

        if (Number(event.currentTarget.value) <= Number(itemDetails.Inventory)
                && event.currentTarget.value !== '0'
                && (event.currentTarget.value === '' || event.currentTarget.value > 0)) {

            props.onItemQtyChangeHandler(event.currentTarget.value, props.replacementIndex);

        }
    };

    const removeOrderItem = (event) => {
    // setItemQuantity(event.currentTarget.value);
        props.removeOrderItem(event, props.replacementIndex);
    };

    const editMode =
      <div>
          <div className="input-box">
              <small className="input-label">Item Number</small>
              <datalist id="orderItems">
                  {options}
              </datalist>
              <input
                  list="orderItems"
                  id="itemNumber"
                  placeholder="Item Number"
                  value={props.replacementItem.ItemNumber}
                  onChange={itemNumberChangeHandler}
              />
          </div>

          <div className="itemDescriptionSection">
              {renderAlternateItems(alternateItems, itemNumberChangeHandler)}
              {itemDetails.Description ?
                  <div className="itemDetailsRow">
                      <div className="itemDetails">
                          <a href={itemUrl} rel="noreferrer" target="_blank"><img className="itemImage" src={itemImage} /></a>
                      </div>
                      <div className="itemDetails">
                          <div className="itemDescription">{itemDetails.Description}</div>
                          <div className="itemNumber">Item #{itemDetails.ItemNumber}</div>
                          <div className="itemInventory">
                              <strong>
                                  {itemDetails.Inventory} available
                              </strong>
                              {itemDetails.Inventory === 0 ? <div>NID: {itemDetails.NextInventoryDate}</div> : null}
                          </div>
                          <div className="itemQuantity">
                              <p>QTY:</p>
                              <input
                                  type="number"
                                  id='quantity'
                                  value={props.replacementItem.Quantity}
                                  onChange={itemQtyChangeHandler}
                                  required
                              />
                              <div>
                                  {(props.replacementIndex > 0 ?
                                      <button className="addRemoveItem removeItem" id="removeItem" onClick={removeOrderItem}>Remove</button>
                                      : null)}
                              </div>
                          </div>
                      </div>
                  </div> : null}
          </div>
      </div>
  ;

    const viewMode =
      <div>
          <div className="input-box">
              <small className="input-label">Item Number</small>
              <datalist id="orderItems">
                  {options}
              </datalist>
              <input
                  list="orderItems"
                  id="itemNumber"
                  placeholder="Item Number"
                  value={props.replacementItem.ItemNumber}
                  onChange={itemNumberChangeHandler}
                  readOnly
              />
          </div>
          {itemDetails.Description ?
              <div className="itemDetailsRow">
                  <div className="itemDetails">
                      <a href={itemUrl} rel="noreferrer" target="_blank"><img className="itemImage" src={itemImage} /></a>
                  </div>
                  <div className="itemDetails">
                      <div className="itemDescription">{itemDetails.Description}</div>
                      <div className="itemNumber">Item #{itemDetails.ItemNumber}</div>
                      <div className="itemInventory"><strong>{itemDetails.Inventory} available</strong></div>
                      <div className="itemQuantity">
                          <p>QTY:</p>
                          <input
                              type="number"
                              id='quantity'
                              value={props.replacementItem.Quantity}
                              onChange={itemQtyChangeHandler}
                              required
                              readOnly
                          />
                      </div>
                  </div>
              </div> : null}
      </div>
  ;

    return props.viewOnly ? viewMode : editMode;
}

export default CaseOrderItem;


const renderAlternateItems = (altItems, swapFunction) => {
    var numItems = altItems.length;
    var rowCount = Math.ceil(numItems / 3); // limit to 3 items per row

    var buttons = altItems.map( (item) =>
        <button className="altItemNumber" key={item} id={item} value={item} onClick={swapFunction}>{item}</button>
    );

    var rows = [];
    var numIndex = 0;
    for(var i = 0; i < rowCount; i++) {
        rows.push(
            <div className="altItemRow" key={i}>
                {buttons[numIndex]}
                {buttons[numIndex + 1]}
                {buttons[numIndex + 2]}
            </div>
        );
        numIndex = numIndex + 3;
    }

    return (
        <div className="altItemsContainer">
            {rows}
        </div>
    );


};
