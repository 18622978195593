import React from 'react';

export default function LoadingIndicator() {
    return (
        <div className="tkn-loading">
            <div className="tkn-spinner">
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div>
            </div>
            <div className="tkn-loading-text">
                Loading...
            </div>
        </div>
    );
}
