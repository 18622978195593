import React from 'react';
import '../css/ChatList.scss';
import ChatListItem from './ChatListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faBellSlash } from '@fortawesome/free-solid-svg-icons';
import { getUnreadMessagesCount } from '../ChatDetails/ChatUtils';

function ChatList(props) {
    const {
        conversations,
        conversationsUser,
        hasMoreConversations,
        onLoadMoreConversations,
        setSelectedConversation,
        selectedConversation,
        setUnreadChatsCount,
        notificationMute,
        setNotificationMute,
    } = props;

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        // Accumulate all the unread chat notifications
        Promise.all(conversations.map(getUnreadMessagesCount))
            .then(unreadMessagesCounts => unreadMessagesCounts.reduce((total, count) => total + count, 0))
            .then(setUnreadChatsCount);
    }, [conversations]);

    const onAvailableClick = e => {
        e.preventDefault();
        setLoading(true);
        conversationsUser.updateAttributes({
            isAvailable: !conversationsUser.attributes.isAvailable
        }).then(() => {
            setLoading(false);
        }, error => {
            console.error(error);
            setLoading(false);
        });
    };

    const onBellClick = e => {
        e.preventDefault();
        setNotificationMute(!notificationMute);
    };

    const isAvailable = conversationsUser.attributes.isAvailable;
    return (
        <div id="chatList">
            <div className="chatListHeader">
                <FontAwesomeIcon
                    icon={notificationMute ? faBellSlash : faBell}
                    className="notificationBell"
                    onClick={onBellClick}
                />
                <h2>Chat List</h2>
                <button disabled={loading} onClick={onAvailableClick} className={isAvailable ? 'chatAvailable' : 'chatUnavailable'}>{isAvailable ? 'Available' : 'Unavailable'}</button>
            </div>
            {props.conversations.length ?
                <div>
                    {conversations.map(conversation =>
                        <ChatListItem
                            key={conversation.sid}
                            onClick={() => setSelectedConversation(conversation)}
                            conversation={conversation}
                            conversationsUser={conversationsUser}
                            setUnreadChatsCount={setUnreadChatsCount}
                            isSelected={selectedConversation && selectedConversation.sid === conversation.sid}
                        />
                    )}
                    {hasMoreConversations ? <button onClick={onLoadMoreConversations} className="loadMoreConversations">Load more</button> : null}
                </div> :
                <div>{props.conversationsState}</div>}
        </div>
    );
}

export default function ChatListWrapper(props) {
    if (props.conversationsUser) {
        return <ChatList {...props}/>;
    }
    return (
        <div id="chatList">
            <div className="chatListHeader">
                <h2>Chat List</h2>
            </div>
            <div>{props.conversationsState}</div>
        </div>
    );
}
