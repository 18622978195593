import React from 'react';
import { withRouter } from 'react-router-dom';
import '../css/CaseListItem.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faTools } from '@fortawesome/free-solid-svg-icons';
import formatDate from '../DateFormatter';
import SenderIcon from '../CaseDetails/SenderIcon';

/**
 * Component that will represent a single case within the case list.
 * This should display: Company, Case Status, and Date Created
 */
function CaseListItem(props) {

    const handleCaseSelection = (event) => {
        props.setSelectedCase(event.currentTarget.id);
        props.history.push('/case/' + event.currentTarget.id);
    };

    const displayImageIcon = props.case.AttachmentCount || props.case.TwilioMessagesWithMediaCount ? 'block' : 'none';
    const displayToolsIcon = props.case.ItemID ? 'block' : 'none';
    const isSelected = parseInt(props.selectedCase) === parseInt(props.case.ID);

    const senderIconId = parseInt(props.selectedCase) === parseInt(props.case.ID)
        ? 'selectedSenderIcon' : 'caseListSenderIcon';

    const displayDate = props.case.FormattedLatestMessageTime
        ? formatDate(props.case.FormattedLatestMessageTime) : 'No messages';

    const name = !props.case.CompanyFirstName && !props.case.CompanyLastName
        ? 'No Name' : props.case.CompanyFirstName + ' ' + props.case.CompanyLastName;

    return (
        <div className={`caseListItem ${isSelected ? 'selected' : ''}`} id={props.case.ID} onClick={handleCaseSelection}>
            <SenderIcon id={senderIconId} senderName={props.case.CompanyFirstName} />
            <p className="caseListName"><b>{name}</b></p>
            <p className="caseListDate">{displayDate}</p>
            <span className="caseListIcons">
                <FontAwesomeIcon id="imageIcon" style={{display: displayImageIcon}} icon={faImage} />
                <FontAwesomeIcon id="toolsIcon" style={{display: displayToolsIcon}} icon={faTools} />
            </span>
        </div>
    );
}

export default withRouter(CaseListItem);
