import React from 'react';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import '../css/QuickEditProperties.scss';
import CaseStatus from './CaseStatus';
import CustomerSupportStatus from './CustomerSupportStatus';
import AssigneeFilter from '../CaseList/AssigneeFilter';
import ErrorPopUp from '../ErrorPopUp';
import getBaseUrl from '../BaseUrl';
import NetSuitePropertyMap from '../NetSuitePropertyMap';

export default function QuickEditProperties(props) {
    const [caseAssignee, setCaseAssignee] = React.useState(props.caseProps.AssigneeID);
    const [openMenu, setOpenMenu] = React.useState(null);
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);

    React.useEffect( () => {
        setCaseAssignee(props.caseProps.AssigneeID);
    }, [props.caseProps.AssigneeID]);

    const assigneeChangeHandler = (newAssigneeId) => {
        setCaseAssignee(newAssigneeId);

        $.ajax({
            url: '/api/assignee/update/case/' + props.caseId + '/assignee/' + newAssigneeId,
            method: 'PUT'
        }).done(() => {
            props.updateCaseList();
        }).fail( (error) => {
            console.error(error);
            setCaseAssignee(caseAssignee);
            setShowErrorMessage(true);
            setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
        });
    };

    const baseUrl = getBaseUrl(props.environment);
    const displayReopenedCaseWarning = NetSuitePropertyMap.STATUS_MAP.get(props.caseProps.Status) === 'Re-Opened'
        && props.messageCount === 0;

    return (
        <div className="editable-properties-container">
            {displayReopenedCaseWarning && <p className="reopened-case-warning">This case has been re-opened. Please check for any previous messages in NetSuite.</p>}
            <div id="editableProperties">
                <ErrorPopUp showErrorMessage={showErrorMessage} />

                <span id="editableButtons">
                    {props.hasPendingOrder ?
                        <span id="orderPendingText">
            Order Pending
                        </span> : null }
                    <AssigneeFilter
                        currentAssignee={caseAssignee}
                        setCurrentAssignee={assigneeChangeHandler}
                        caseListComponent={false}
                        openMenu={openMenu}
                        setOpenMenu={setOpenMenu}
                    />
                    <CaseStatus
                        status={props.caseProps.Status}
                        selectedCase={props.caseId}
                        openMenu={openMenu}
                        setOpenMenu={setOpenMenu}
                        updateCaseList={props.updateCaseList}
                        updateCaseDetails={props.updateCaseDetails}
                        problemItem={props.caseProps.ItemNumber}
                    />
                    <CustomerSupportStatus
                        useMobileLayout={false}
                        selectedCase={props.caseId}
                        customerSupportStatus={props.caseProps.CustomerSupportStatus}
                        caseCount={props.caseProps.CaseCount}
                        openMenu={openMenu}
                        setOpenMenu={setOpenMenu}
                    />
                </span>

                {!props.useMobileLayout && <a href={baseUrl + '/app/crm/support/supportcase.nl?id=' + props.caseProps.NetSuiteID + '&whence='} className="externalLink" target="_blank" rel="noopener noreferrer">
                    <h3>{props.caseProps.ExternalID ? props.caseProps.ExternalID : props.caseProps.NetSuiteID} <FontAwesomeIcon icon={faExternalLinkAlt} /></h3>
                </a> }
            </div>
        </div>
    );
}
