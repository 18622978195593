import React from 'react';
import _ from 'lodash';

// Provides a current state value and a debounced state value for
// requests that may be sent on state changes
export default function useDebouncedState(initialValue, wait = 500) {
    const [currentValue, setCurrentValue] = React.useState(initialValue);
    const [debouncedValue, setDebouncedValue] = React.useState(initialValue);
    const setDebounced = React.useRef(_.debounce(setDebouncedValue, wait));

    const set = value => {
        setCurrentValue(value);
        setDebounced.current(value);
    };

    return [
        currentValue,
        debouncedValue,
        set,
    ];
}
