import React from 'react';
import $ from 'jquery';
import '../css/CaseList.scss';
import { useLocation } from 'react-router-dom';
import AssigneeFilter from './AssigneeFilter';
import PendingResponseFilter from './PendingResponseFilter';
import CaseListItem from './CaseListItem';
import EditPropertiesModal from '../NewCase/EditPropertiesModal';
import useCollapsable from '../customHooks/useCollapsable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export default function CaseList(props) {
    const search = useLocation().search;
    let newCaseData;
    try {
        newCaseData = JSON.parse(new URLSearchParams(search).get('caseData'));
    } catch (e) {
        console.error('Invalid caseData object');
    }

    const [caseItems, setCaseItems] = React.useState([]);
    const [refreshCount, setRefreshCount] = React.useState(0);
    const [currentAssignee, setCurrentAssignee] = React.useState(null);
    const [pendingResponseFrom, setPendingResponseFrom] = React.useState('waitingOnEmployee');
    const [showNewCaseModal, toggleShowNewCaseModal] = useCollapsable(Boolean(newCaseData));
    const itemDataList = props.itemDataList;

    // This effect updates the Case list data on any relevant UI change and
    // every 5 minutes
    React.useEffect( () => {
        if (!currentAssignee) {
            return;
        }
        retrieveAndSetCaseListData('/api/case/retrieveList/' + currentAssignee, setCaseItems);
    }, [currentAssignee, refreshCount, props.refreshCount]);

    const updateRefreshCount = React.useCallback(() => {
        setRefreshCount(refreshCount + 1);
    }, [refreshCount]);

    // This tells React to update the case list data every 1 minute
    React.useEffect(() => {
        setTimeout(() => {
            updateRefreshCount();
        }, 60000);
    }, [refreshCount, updateRefreshCount]);

    const pendingEmployeeResponseCount = caseItems.waitingOnEmployee ? caseItems.waitingOnEmployee.length : 0;

    React.useEffect(() => {
        props.setUnreadEmailsCount(pendingEmployeeResponseCount);
    });

    if (props.useMobileLayout && props.selectedCase) {
        return <div></div>;
    }

    const caseItemList = caseItems[pendingResponseFrom] || [];

    const pendingCustomerResponseCount = caseItems.waitingOnCustomer ? caseItems.waitingOnCustomer.length : 0;
    const pendingUserResponseCount = caseItems.pendingUserResponse ? caseItems.pendingUserResponse.length : 0;
    const closedCaseCount = caseItems.closedCases ? caseItems.closedCases.length : 0;

    return (
        <span className="caseListContainer">
            {renderNewCaseModal(showNewCaseModal, toggleShowNewCaseModal,
                updateRefreshCount, itemDataList, newCaseData)}
            <div className="caseList">
                <button
                    id="newCaseButton"
                    className="standardButton"
                    onClick={toggleShowNewCaseModal}>
                    <FontAwesomeIcon id="newCaseIcon" icon={faPlus} />
                </button>
                <h2 id="caseListHeader">Case List</h2>
                <div id="caseListFilters">
                    <AssigneeFilter
                        setCurrentAssignee={setCurrentAssignee}
                        currentAssignee={currentAssignee}
                        caseListComponent={true}
                    />
                    <PendingResponseFilter
                        pendingResponseFrom={pendingResponseFrom}
                        setPendingResponseFrom={setPendingResponseFrom}
                        pendingCustomerResponseCount={pendingCustomerResponseCount}
                        pendingEmployeeResponseCount={pendingEmployeeResponseCount}
                        pendingUserResponseCount={pendingUserResponseCount}
                        closedCaseCount={closedCaseCount}
                    />
                </div>
                {renderCaseListItems(caseItemList, props.setSelectedCase, props.selectedCase)}
            </div>
        </span>
    );
}

const renderNewCaseModal = (showNewCaseModal, toggleShowNewCaseModal, updateRefreshCount, itemDataList, newCaseData) => {
    if (!showNewCaseModal) {
        return null;
    }

    return <EditPropertiesModal
        key={'newCase'}
        showNewCaseModal={showNewCaseModal}
        toggleShowNewCaseModal={toggleShowNewCaseModal}
        updateRefreshCount={updateRefreshCount}
        newCaseData={newCaseData}
        itemDataList={itemDataList}
    />;
};

const renderCaseListItems = (caseItems, caseSelectionCallback, selectedCase) => {
    return caseItems.map( (caseItem) =>
        <CaseListItem
            case={caseItem}
            key={caseItem.ID}
            setSelectedCase={caseSelectionCallback}
            selectedCase={selectedCase}
        />
    );
};

const retrieveAndSetCaseListData = (url, setCaseItems) => {
    $.get(url)
        .done( (response) => {
            setCaseItems(response);
        })
        .fail( (error) => {
            console.error('Unable to retrieve case list data: '
                          + JSON.stringify(error));
        });
};
