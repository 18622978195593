import React from 'react';
import $ from 'jquery';
import '../css/CustomerSupportStatus.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import useCollapsable from '../customHooks/useCollapsable';
import useOpenMenuEffect from '../customHooks/useOpenMenuEffect';
import ErrorPopUp from '../ErrorPopUp';
import NetSuitePropertyMap from '../NetSuitePropertyMap';

export default function CustomerSupportStatus(props) {
    const [collapsed, toggleCollapsed] = useCollapsable(true);
    const [customerSupportStatus, setCustomerSupportStatus] =
      React.useState('Loading...');
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);

    const mainOptionClickHandler = () => {
        toggleCollapsed();

        // Not collapsed means that it is about to be collapsed
        const menuOpenValue = !collapsed ? null : 'supportStatus';
        props.setOpenMenu(menuOpenValue);
    };


    useOpenMenuEffect(props.openMenu, 'supportStatus', collapsed, toggleCollapsed);

    React.useEffect( () => {
        setCustomerSupportStatus(props.customerSupportStatus);
    }, [props.customerSupportStatus] );

    const icon = collapsed ? faCaretDown : faCaretUp;
    const supportStatusText = NetSuitePropertyMap.SUPPORT_STATUS_MAP[customerSupportStatus];

    return (
        <div className="customerSupportStatusSelector" onClick={mainOptionClickHandler}>
            <ErrorPopUp showErrorMessage={showErrorMessage} />
            <span className="labelContainer">
                <label htmlFor={customerSupportStatus + 'SupportStatus'}>CUSTOMER STATUS: </label>
                <span className="dropDownContainer">
                    <button
                        className="supportStatusOption"
                        id={supportStatusText + 'SupportStatus'}
                    >
                        {supportStatusText}<FontAwesomeIcon id="dropDownIcon" icon={icon}/>
                    </button>
                    <div className="optionContainer">
                        {renderDropDownItems(collapsed, customerSupportStatus,
                            toggleCollapsed, setCustomerSupportStatus, props.selectedCase,
                            props.caseCount, setShowErrorMessage)}
                    </div>
                </span>
            </span>
        </div>
    );
}

const renderDropDownItems = (collapsed, currentStatus, toggleCollapsed,
    setCustomerSupportStatus, caseId, caseCount,
    setShowErrorMessage) => {
    if (collapsed) {
        return null;
    }

    const statusOptions = Object.entries(NetSuitePropertyMap.SUPPORT_STATUS_MAP);
    if (caseCount > 4) {
        statusOptions.splice(0, 1);
    }

    const optionClickHandler = (event) => {
        $.ajax({
            url: '/api/case/customerSupportStatus/' + caseId + '/' + event.currentTarget.id,
            type: 'PUT'
        }).fail( (error) => {
            console.error(error);
            setCustomerSupportStatus(currentStatus);
            setShowErrorMessage(true);
            setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
        });
        setCustomerSupportStatus(event.currentTarget.id);
        toggleCollapsed();
    };

    return statusOptions.map( (option) => {
        return (
            <button className="supportStatusOption"
                id={option[0]}
                key={option[0]}
                onClick={optionClickHandler}>
                {option[1]}
            </button>
        );
    });
};
