import React from 'react';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faChevronCircleDown, faChevronCircleUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import '../css/CaseOrder.scss';
import CaseOrderItem from '../CaseOrder/CaseOrderItem.js';
import ErrorPopUp from '../ErrorPopUp';
import NetSuiteAddressSelector from '../NetSuiteAddressSelector';
import {
    CancelableRequest,
    updateCaseReplacementReason
} from '../Utils';
import _ from 'lodash';
// import {faPlus} from "@fortawesome/free-solid-svg-icons/index";

const emptyFieldsObject = {
    customerFirstName: '',
    customerLastName: '',
    emailAddress: '',
    problemItemNumber: '',
    problemReason: '',
    problemReasonText: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
};

const emptyItemDetails = {
    Description: '',
    Inventory: '',
    ImageUrl: '',
    Nickname: '',
};

const emptyReplacementItem = {
    ItemNumber : '',
    Quantity : '',
    ID: '',
};

const emptyOrderLine = {
    ItemID: '',
    Quantity: '',
};

const emptyPendingOrder = [{...emptyOrderLine}];

function CaseOrder(props) {
    const [collapsed, setCollapsed] = React.useState(false);
    const [formFields, setFormFieldsWrapped] = React.useState({...emptyFieldsObject});
    // const [replacementItemCount, setReplacementItemCount] = React.useState(1);
    const [replacementItems, setReplacementItems] = React.useState([{...emptyReplacementItem}]);
    const [problemItemDetails, setProblemItemDetails] = React.useState({...emptyItemDetails});
    const [problemItemImage, setProblemItemImage] = React.useState();
    const [problemItemUrl, setProblemItemUrl] = React.useState();
    const [rewardsInstead, setRewardsInstead] = React.useState(10);
    const [qualifiesForRewards, setQualifiesForRewards] = React.useState(0);
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);
    // const [orderSubmitted, setOrderSubmitted] = React.useState(false);
    const [pendingOrderTimer, setPendingOrderTimer] = React.useState(false);
    const [pendingOrder, setPendingOrder] = React.useState(emptyPendingOrder);
    const [getLiveInventory, setGetLiveInventory] = React.useState(false);
    const [caseProperties, setCaseProperties] = React.useState({});
    // const [alternateItems, setAlternateItems] = React.useState([]);
    const [alternateItems, setAlternateItems] = React.useState([]);
    const [resetAddress, setResetAddress] = React.useState();

    const setFormFields = value => {
        if (!resetAddress) {
            setResetAddress({
                addressLine1: formFields.addressLine1,
                addressLine2: formFields.addressLine2,
                city: formFields.city,
                state: formFields.state,
                country: formFields.country,
                zipCode: formFields.zipCode
            });
        }
        setFormFieldsWrapped(value);
    };

    React.useEffect(() => {
        if (!props.selectedCase) {
            return;
        }

        const request = new CancelableRequest($.get('/api/case/properties/' + props.selectedCase))
            .then(response => {
                setCaseProperties(response);
            }, error => {
                if (error !== 'unmount') {
                    console.error('Unable to get case order properties: '
                                  + JSON.stringify(error));
                }
            });
        return () => request.cancel();
    }, [props.selectedCase]);

    const itemDataList = _.uniq(props.itemDataList);

    const collapseButtonHandler = () => {
        setCollapsed(!collapsed);
    };

    // Get problem item details
    React.useEffect(() => {
        if (formFields.problemItemNumber) {
            const request = new CancelableRequest($.get('/api/item/problemItemDetails/' + formFields.problemItemNumber))
                .then(response => {
                    if (response[0]) {
                        setProblemItemDetails(response[0]);

                        if (response[0].DaysInSupply <= 2) {
                            setGetLiveInventory(true);
                        }
                    }
                }, error => {
                    console.error(JSON.stringify(error));
                });
            return () => request.cancel();
        }
        setProblemItemDetails(emptyItemDetails);
        setReplacementItems([{...emptyReplacementItem}]);
    }, [formFields.problemItemNumber, caseProperties]);


    React.useEffect(() => {
        if (formFields.problemItemNumber) {
            // Get alternate items
            const request = new CancelableRequest($.get('/api/item/alternateItemList/' + formFields.problemItemNumber))
                .then(response => {
                    if (response) {
                        setAlternateItems(response);
                        // alternateItems = response;
                    }else{
                        setAlternateItems([]);
                        console.log('there was no response body. alternateItems = ' + alternateItems);
                        // alternateItems = [];
                    }

                }, error => {
                    console.error(JSON.stringify(error));
                });
            return () => request.cancel();
        }
        setAlternateItems([]);
    }, [formFields.problemItemNumber]);

    // Set image and url, and default replacement item when problem item changes
    React.useEffect(() => {
        if(problemItemDetails.ItemNumber) {
            if(!problemItemImage) {
                // There is no problem item image already, this is the first time
                // we're loading it so set the replacement items
                const newReplacementItems = [...replacementItems];
                newReplacementItems[0].ItemNumber = problemItemDetails.ItemNumber;
                setReplacementItems( newReplacementItems );
            }
            setProblemItemImage( 'https://www.tekton.com/images/product/' + problemItemDetails.Nickname + '_1.jpg' );
            setProblemItemUrl( 'https://www.tekton.com/' + problemItemDetails.UrlComponent );


        }

        // console.log("updating problem item");
        if (problemItemDetails.ID) {
            const updateProblemItemBody = {
                caseId: props.selectedCase,
                problemItemId: problemItemDetails.ID
            };
            const request = new CancelableRequest($.ajax({
                url: '/api/case/updateProblemItem',
                method: 'PUT',
                data: updateProblemItemBody
            }))
                .then(() => {
                    props.updateRefreshCount();
                }, error => {
                    console.error('Unable to update problem item number: ' + JSON.stringify(error));
                });
            return () => request.cancel();
        }
    }, [problemItemDetails]);

    // Set default values from the case properties into the sales order
    React.useEffect(() => {
        formFields.customerFirstName = caseProperties.CustomerFirstName || '';
        formFields.customerLastName = caseProperties.CustomerLastName || '';
        formFields.emailAddress = caseProperties.EmailAddress;
        formFields.addressLine1 = caseProperties.AddrOne || '';
        formFields.addressLine2 = caseProperties.AddrTwo || '';
        formFields.city = caseProperties.City || '';
        formFields.state = caseProperties.State || '';
        formFields.country = determineCountry(caseProperties.ZipCode);
        formFields.zipCode = caseProperties.ZipCode || '';
        formFields.problemItemNumber = caseProperties.ItemNumber || '';
        formFields.problemReason = caseProperties.ReplacementReason || '10';
        replacementItems[0].ItemNumber = caseProperties.ItemNumber || '';
        replacementItems[0].ID = '';
        replacementItems[0].Quantity = '';
        setQualifiesForRewards(caseProperties.QualifiesForRewards);

        if(caseProperties.ID) {

            const request = new CancelableRequest($.get( '/api/order/pendingOrderDetails/' + caseProperties.ID ))
                .then(response => {
                    if( response ) {
                        setPendingOrder( response );
                    }
                }, error => {
                    console.error( JSON.stringify( error ) );
                    setPendingOrder(emptyPendingOrder);
                });
            return () => request.cancel();
        }

    }, [caseProperties] );

    React.useEffect(() => {
        if (caseProperties.ID) {
            const request = new CancelableRequest($.get( '/api/order/pendingOrderDetails/' + caseProperties.ID ))
                .then(response => {
                    if( response ) {
                        setPendingOrder( response );
                    }
                }, error => {
                    console.error( JSON.stringify( error ) );
                    setPendingOrder(emptyPendingOrder);
                });
            return () => request.cancel();
        }
    }, [props.hasPendingOrder]);

    // If we have a pendingOrder, update replacement items array to it for display
    React.useEffect(() => {
        if(pendingOrder && pendingOrder[0] && pendingOrder[0].OrderID) {
            // setOrderSubmitted(true);
            props.setHasPendingOrder(true);
            const newReplacementItems = [...replacementItems];
            for(var i = 0; i < pendingOrder.length; i++) {
                newReplacementItems[i] = {
                    ItemNumber: pendingOrder[i].ItemNumber,
                    ID: pendingOrder[i].ItemID,
                    Quantity: pendingOrder[i].Quantity,
                };
            }
            // newReplacementItems[0] = {
            //   ItemNumber: pendingOrder[0].ItemNumber,
            //   ID: pendingOrder[0].ItemID,
            //   Quantity: pendingOrder[0].Quantity,
            // };
            // newReplacementItems[1] = {
            //   ItemNumber: pendingOrder[1].ItemNumber,
            //   ID: pendingOrder[1].ItemID,
            //   Quantity: pendingOrder[1].Quantity,
            // };
            setReplacementItems(newReplacementItems);

            // if(pendingOrder.length > 1){
            //   for(var i = 1; i < pendingOrder.length; i++){
            //     // if(replacementItems[i]){
            //     //   newReplacementItems[i] = {
            //     //     ItemNumber: pendingOrder[i].ItemNumber,
            //     //     ID: pendingOrder[i].ItemID,
            //     //     Quantity: pendingOrder[i].Quantity,
            //     //   };
            //     // }else{
            //     const newReplacementItems = [...replacementItems];
            //     newReplacementItems[replacementItems.length] = {
            //       ItemNumber: "",
            //       Quantity: "1"
            //     };
            //     setReplacementItems(newReplacementItems);
            //
            //     const newReplacementItems2 = [...replacementItems];
            //       newReplacementItems[i] = {
            //         ItemNumber: pendingOrder[i].ItemNumber,
            //         ID: pendingOrder[i].ItemID,
            //         Quantity: pendingOrder[i].Quantity,
            //       };
            //     setReplacementItems(newReplacementItems2);
            //     // }
            //   }
            // }

        }else{
            // setOrderSubmitted(false);
            props.setHasPendingOrder(false);

        }
    }, [pendingOrder]);

    // Get live inventory calculation every time the item is under 2 days supply
    React.useEffect(() => {
        if (!getLiveInventory) {
            return;
        }

        const request = new CancelableRequest($.get('/api/item/liveInventory/' + problemItemDetails.NetSuiteID))
            .then(response => {
                if (response.success) {
                    const newItemDetails = {...problemItemDetails};
                    newItemDetails.Inventory = response.inventory;
                    setProblemItemDetails(newItemDetails);
                } else {
                    console.error('Error retrieving live inventory: ' + response.message);
                    const newItemDetails = {...problemItemDetails};
                    newItemDetails.Inventory = 0;
                    setProblemItemDetails(newItemDetails);
                }
            }, error => {
                console.error('Error retrieving live inventory: ' + JSON.stringify(error));
                const newItemDetails = {...problemItemDetails};
                newItemDetails.Inventory = 0;
                setProblemItemDetails(newItemDetails);
            });

        setGetLiveInventory(false);
        return () => request.cancel();
    }, [setProblemItemDetails, getLiveInventory]);

    // Set options for use in problem Item
    const options = React.useMemo(() => {
        return itemDataList.map(item => <option key={item}>{item}</option>);
    }, [itemDataList]);

    let minutesRemaining = null;
    let secondsRemaining = null;
    if (pendingOrder && pendingOrder[0] && pendingOrder[0].CreationTime) {
        const orderProcessingTime = new Date(pendingOrder[0].CreationTime);
        const minutes = orderProcessingTime.getMinutes();
        if (minutes < 45) {
            const estDownloadMinutes = minutes < 15 ? 15 : minutes < 30 ? 30 : 45;
            orderProcessingTime.setMinutes(estDownloadMinutes);
        } else {
            orderProcessingTime.setHours(orderProcessingTime.getHours() + 1);
            orderProcessingTime.setMinutes(0);
        }
        orderProcessingTime.setSeconds(0);

        const now = new Date();
        minutesRemaining = orderProcessingTime.getHours() * 60 + orderProcessingTime.getMinutes() - (now.getHours() * 60 + now.getMinutes() + 1);
        secondsRemaining = 60 - now.getSeconds();
    }

    React.useEffect(() => {
        if (pendingOrder
      && pendingOrder[0]
      && pendingOrder[0].OrderID
        && minutesRemaining !== null
        && minutesRemaining >= 0) {

            setTimeout(() => setPendingOrderTimer(new Date().toISOString()), 250);
        }
    }, [pendingOrder, pendingOrderTimer]);

    function cancelOrder() {
        const caseUpdateUrl = '/api/order/deleteCaseOrder/' + pendingOrder[0].OrderID;

        $.ajax({
            url: caseUpdateUrl,
            method: 'PUT',
            contentType: 'application/json'
        }).done(() => {
            // setOrderSubmitted(false);
            props.setHasPendingOrder(false);
            setPendingOrder(emptyPendingOrder);
            minutesRemaining = null;
            secondsRemaining = null;
        }).fail( (error) => {
            console.error(JSON.stringify(error));
            setShowErrorMessage(true);
            setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
        });
    }

    function handleSubmit(event) {
    // Prevent automatic reloading
        event.preventDefault();

        for (let index = 0; index < replacementItems.length; index++) {
            const pendingItem = replacementItems[index];
            if (!pendingItem.Quantity) {
                const newReplacementItems = [...replacementItems];
                newReplacementItems[index].ItemNumber = '';
                // console.log("new items: " + JSON.stringify(newReplacementItems));
                setReplacementItems(newReplacementItems);
                return;
            }
        }

        if (!window.confirm('Are you sure that you want to submit this order?')) {
            return;
        }

        console.log('submitting order: ' + event.currentTarget.id);
        const addressRequestBody = {
            // Warranty support
            CaseIssue: 1,
            ReplacementReason: formFields.problemReason,
            ItemNumber: formFields.problemItemNumber,
            AddrOne: formFields.addressLine1,
            AddrTwo: formFields.addressLine2,
            City: formFields.city,
            State: formFields.state,
            Country: formFields.country,
            ZipCode: formFields.zipCode,
            Status: 'Not Started',
            DateCreated: new Date().toLocaleString(),
            Customer: {
                FirstName: formFields.customerFirstName,
                LastName: formFields.customerLastName,
                EmailAddress: formFields.emailAddress,
                SupportStatus: 'Good'
            }
        };

        const caseUpdateUrl = '/api/case/editProperties/' + caseProperties.ID;
        $.ajax({
            url: caseUpdateUrl,
            method: 'PUT',
            data: JSON.stringify(addressRequestBody),
            contentType: 'application/json'
        }).done(() => {
            // setOrderSubmitted(true);
        }).fail( (error) => {
            console.error(JSON.stringify(error));
            setShowErrorMessage(true);
            setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
        });

        const requestBody = {
            // SubmissionStatus: 'Pending',
            Lines: replacementItems
        };

        const url = '/api/order/addCaseOrder/' + caseProperties.ID;
        $.ajax({
            url: url,
            method: 'PUT',
            data: JSON.stringify(requestBody),
            contentType: 'application/json'
        }).done(() => {
            // setOrderSubmitted(true);
            props.setHasPendingOrder(true);
        }).fail( (error) => {
            console.error(JSON.stringify(error));
            setShowErrorMessage(true);
            setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
        });


        if (problemItemDetails.ID) {
            const updateProblemItemBody = {
                caseId: props.selectedCase,
                problemItemId: problemItemDetails.ID
            };
            $.ajax({
                url: '/api/case/updateProblemItem',
                method: 'PUT',
                data: updateProblemItemBody
            }).done(() => {
                props.updateRefreshCount();
            }).fail((error) => {
                console.error('Unable to update problem item number: ' + JSON.stringify(error));
            });
        }
    }

    const onFieldChangeHandler = (event) => {
        const newFields = {...formFields};
        newFields[event.currentTarget.id] = event.currentTarget.value;
        setFormFields(newFields);

        if (event.currentTarget.id === 'problemReason') {
            updateCaseReplacementReason(props.selectedCase, event.currentTarget.value, setShowErrorMessage);
        }
    };
    const collapseIcon = collapsed ? faChevronCircleDown : faChevronCircleUp;

    const onReplacementItemNumberChangeHandler = (number, index) => {
        const newReplacementItems = [...replacementItems];
        newReplacementItems[index].ItemNumber = number;
        setReplacementItems(newReplacementItems);
    };

    const onReplacementItemIDChangeHandler = (ID, index) => {
        const newReplacementItems = [...replacementItems];
        newReplacementItems[index].ID = ID;
        setReplacementItems(newReplacementItems);
    };

    const onReplacementItemQtyChangeHandler = (value, index) => {
        const newReplacementItems = [...replacementItems];
        newReplacementItems[index].Quantity = value;
        setReplacementItems(newReplacementItems);
    };

    const addReplacementItem = (event) => {
        event.preventDefault();
        const newReplacementItems = [...replacementItems];
        newReplacementItems[replacementItems.length] = {
            ItemNumber: '',
            Quantity: '1'
        };
        setReplacementItems(newReplacementItems);
    };
    const removeReplacementItem = (event, index) => {
        event.preventDefault();

        const newReplacementItems = [...replacementItems];
        newReplacementItems.splice(index, 1);
        setReplacementItems(newReplacementItems);
    };

    const rewardsChangeHandler = (event) => {
        const newRewardsInstead = event.currentTarget.value;
        setRewardsInstead(newRewardsInstead.substring(0, 3));
    };
    const addRewardsToAccount = () => {
        if(window.confirm('$' + rewardsInstead + ' in rewards will be added immediately. Proceed?')) {
            $.ajax({
                url: '/api/rewards/updateRewards/' + props.selectedCase,
                method: 'PUT',
                data: {
                    rewardsToAdd: rewardsInstead
                }
            }).done(() => {
                const newMessageRequestBody = {
                    CaseID: props.selectedCase,
                    MessageText: 'Added $' + rewardsInstead + ' to the account.',
                    InternalOnly: true
                };

                $.post('/api/message/sendMessage', newMessageRequestBody)
                    .done(() => {
                        props.updateRefreshCount();
                        const newCaseProps = {...caseProperties};
                        const currentRewards = parseInt(caseProperties.RewardsAddedToCustomer);
                        newCaseProps.RewardsAddedToCustomer = currentRewards + parseInt(rewardsInstead);
                        setCaseProperties(newCaseProps);
                    })
                    .fail((error) => {
                        console.error('Unable to add message to case: ' + error);
                    });

                setRewardsInstead(10);
            }).fail((error) => {
                console.error('Unable to add rewards: ' + JSON.stringify(error));
                setShowErrorMessage(true);
                setTimeout(() => setShowErrorMessage(false), 5000);
            });
        }
    };

    const onAddressChange = address => {
        setFormFields({
            ...formFields,
            addressLine1: address.addr1,
            addressLine2: address.addr2 || '',
            city: address.city,
            state: address.state,
            country: address.country,
            zipCode: address.zip,
        });
    };

    const onResetAddress = e => {
        e.preventDefault();
        setFormFields({
            ...formFields,
            ...resetAddress
        });
        setResetAddress();
    };

    const stateArray = [
        {
            'text':'State',
            'code': ''
        },
        {
            'text':'Alabama',
            'code': 'AL'
        },
        {
            'text':'Alaska',
            'code': 'AK'
        },
        {
            'text':'Arizona',
            'code': 'AZ'
        },
        {
            'text':'Arkansas',
            'code': 'AR'
        },
        {
            'text': 'Armed Forces Americas',
            'code': 'AA'
        },
        {
            'text': 'Armed Forces Europe',
            'code': 'AE'
        },
        {
            'text': 'Armed Forces Pacific',
            'code': 'AP'
        },
        {
            'text':'California',
            'code': 'CA'
        },
        {
            'text':'Colorado',
            'code': 'CO'
        },
        {
            'text':'Connecticut',
            'code': 'CT'
        },
        {
            'text':'Delaware',
            'code': 'DE'
        },
        {
            'text':'District of Columbia',
            'code': 'DC'
        },
        {
            'text':'Florida',
            'code': 'FL'
        },
        {
            'text':'Georgia',
            'code': 'GA'
        },
        {
            'text':'Hawaii',
            'code': 'HI'
        },
        {
            'text':'Idaho',
            'code': 'ID'
        },
        {
            'text':'Illinois',
            'code': 'IL'
        },
        {
            'text':'Indiana',
            'code': 'IN'
        },
        {
            'text':'Iowa',
            'code': 'IA'
        },
        {
            'text':'Kansas',
            'code': 'KS'
        },
        {
            'text':'Kentucky',
            'code': 'KY'
        },
        {
            'text':'Louisiana',
            'code': 'LA'
        },
        {
            'text':'Maine',
            'code': 'ME'
        },
        {
            'text':'Maryland',
            'code': 'MD'
        },
        {
            'text':'Massachussettes',
            'code': 'MA'
        },
        {
            'text':'Michigan',
            'code': 'MI'
        },
        {
            'text':'Minnesota',
            'code': 'MN'
        },
        {
            'text':'Mississippi',
            'code': 'MS'
        },
        {
            'text':'Missouri',
            'code': 'MO'
        },
        {
            'text':'Montana',
            'code': 'MT'
        },
        {
            'text':'Nebraska',
            'code': 'NE'
        },
        {
            'text':'Nevada',
            'code': 'NV'
        },
        {
            'text':'New Hampshire',
            'code': 'NH'
        },
        {
            'text':'New Jersey',
            'code': 'NJ'
        },
        {
            'text':'New Mexico',
            'code': 'NM'
        },
        {
            'text':'New York',
            'code': 'NY'
        },
        {
            'text':'North Carolina',
            'code': 'NC'
        },
        {
            'text':'North Dakota',
            'code': 'ND'
        },
        {
            'text':'Ohio',
            'code': 'OH'
        },
        {
            'text':'Oklahoma',
            'code': 'OK'
        },
        {
            'text':'Oregon',
            'code': 'OR'
        },
        {
            'text':'Pennsylvania',
            'code': 'PA'
        },
        {
            'text':'Rhode Island',
            'code': 'RI'
        },
        {
            'text':'South Carolina',
            'code': 'SC'
        },
        {
            'text':'South Dakota',
            'code': 'SD'
        },
        {
            'text':'Tennessee',
            'code': 'TN'
        },
        {
            'text':'Texas',
            'code': 'TX'
        },
        {
            'text':'Utah',
            'code': 'UT'
        },
        {
            'text':'Vermont',
            'code': 'VT'
        },
        {
            'text':'Virginia',
            'code': 'VA'
        },
        {
            'text':'West Virginia',
            'code': 'WV'
        },
        {
            'text':'Washington',
            'code': 'WA'
        },
        {
            'text':'Wisconsin',
            'code': 'WI'
        },
        {
            'text':'Wyoming',
            'code': 'WY'
        },
    ];
    const stateOptions = React.useMemo(() => {
        return stateArray.map(item => <option key={item.code} value={item.code}>{item.text}</option>);
    }, [stateArray]);


    const provinceArray = [
        {
            'text':'Province',
            'code': ''
        },
        {
            'text':'Alberta',
            'code': 'AB'
        },
        {
            'text':'British Columbia',
            'code': 'BC'
        },
        {
            'text':'Manitoba',
            'code': 'MB'
        }, {
            'text':'New Brunswick',
            'code': 'NB'
        },
        {
            'text':'Newfoundland & Labrador',
            'code': 'NL'
        },
        {
            'text':'Northwest Territories',
            'code': 'NT'
        },
        {
            'text':'Nova Scotia',
            'code': 'NS'
        },
        {
            'text':'Nunavut',
            'code': 'NU'
        },
        {
            'text':'Ontario',
            'code': 'ON'
        },
        {
            'text':'Prince Edward Island',
            'code': 'PE'
        },
        {
            'text':'Quebec',
            'code': 'QC'
        },
        {
            'text':'Saskatchewan',
            'code': 'SK'
        },
        {
            'text':'Yukon',
            'code': 'YT'
        },
    ];
    const provinceOptions = React.useMemo(() => {
        return provinceArray.map(item => <option key={item.code} value={item.code}>{item.text}</option>);
    }, [provinceArray]);

    const swapItemNumber = (event) => {
        event.preventDefault();

        const newFields = {...formFields};
        newFields['problemItemNumber'] = event.currentTarget.value;
        setFormFields(newFields);
    };

    const orderForm = collapsed ? null :
        <form id="caseOrderForm" className="caseOrderForm" onSubmit={handleSubmit} >
            <div id="endUserInfo" className='orderFormSection'>
                <div className='orderFormSectionTitle'>Customer Information</div>
                <div className='sectionContent'>
                    <div className='userDetails'>
                        <p>
                            <strong>Name: </strong>{formFields.customerFirstName} {formFields.customerLastName}
                            <br />
                            <strong>Email: </strong>{formFields.emailAddress}
                        </p>
                    </div>
                    <div>
                        <div className="addressSelector">

                            <NetSuiteAddressSelector
                                netsuiteId={caseProperties.CustomerNetSuiteID}
                                onAddressChange={onAddressChange}
                                currentAddress={{
                                    addr1: formFields.addressLine1,
                                    addr2: formFields.addressLine2,
                                    city: formFields.city,
                                    state: formFields.state,
                                    country: formFields.country,
                                    zip: formFields.zipCode
                                }}
                            />
                            {resetAddress ? <button className="standardButton" onClick={onResetAddress}>RESET</button> : null}
                        </div>
                        {caseProperties.VerifiedCustomer ?
                            null :
                            <span className="unverifiedCustomer">

                                <FontAwesomeIcon id="warningIcon" icon={faExclamationTriangle} />
                                Unverified Customer: Don&rsquo;t share address information
                            </span>
                        }
                    </div>
                    <div className='userAddress'>
                        <div className="userAddressRow">
                            <div className="input-box">
                                <small className="input-label">Address Line 1</small>
                                <input type='text'
                                    id='addressLine1'
                                    placeholder='Address Line 1'
                                    value={formFields.addressLine1}
                                    onChange={onFieldChangeHandler}
                                    required
                                />
                            </div>
                            <div className="input-box">
                                <small className="input-label">Address Line 2</small>
                                <input type='text'
                                    id='addressLine2'
                                    placeholder='Address Line 2'
                                    value={formFields.addressLine2}
                                    onChange={onFieldChangeHandler}
                                />
                            </div>
                        </div>
                        <div className="userAddressRow">
                            <div className="input-box">
                                <small className="input-label">City</small>
                                <input
                                    type='text'
                                    id='city'
                                    placeholder='City'
                                    value={formFields.city}
                                    onChange={onFieldChangeHandler}
                                    required
                                />
                            </div>
                            <div className="input-box">
                                <small className="input-label">State</small>
                                <select
                                    required
                                    id='state'
                                    value={formFields.state}
                                    onChange={onFieldChangeHandler}
                                >
                                    { formFields.country === 'CA' ? provinceOptions : stateOptions }
                                </select>
                            </div>
                        </div>
                        <div className="userAddressRow">
                            <div className="input-box">
                                <small className="input-label">Country</small>
                                <select
                                    required
                                    id='country'
                                    value={formFields.country}
                                    onChange={onFieldChangeHandler}
                                >
                                    <option value="US">United States</option>
                                    <option value="CA">Canada</option>
                                </select>
                            </div>
                            <div className="input-box">
                                <small className="input-label">Zip Code</small>
                                <input type='text'
                                    id='zipCode'
                                    placeholder='Zip'
                                    value={formFields.zipCode}
                                    onChange={onFieldChangeHandler}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="itemsRow">
                <div id="problemItemInfo" className='orderFormSection itemSection'>
                    <div className='orderFormSectionTitle'>Problem Item</div>
                    <div className='sectionContent'>
                        <div className="itemDetailsSection">
                            <div className="input-box">
                                <small className="input-label">Item Number</small>
                                <input
                                    list="items"
                                    id="problemItemNumber"
                                    placeholder="Item Number"
                                    value={formFields.problemItemNumber}
                                    onChange={onFieldChangeHandler}
                                />
                                <datalist id="items">
                                    {options}
                                </datalist>
                            </div>
                            {formFields.problemItemNumber && problemItemDetails.Description ?
                                <div className="itemDescriptionSection">
                                    {renderAlternateItems(alternateItems, swapItemNumber)}
                                    <div className="itemDetailsRow">
                                        <div className="itemDetails">
                                            <a href={problemItemUrl} target="_blank" rel="noreferrer"><img className="itemImage" src={problemItemImage} /></a>
                                        </div>
                                        <div className="itemDetails">
                                            <div className="itemDescription">{problemItemDetails.Description}</div>
                                            <div className="itemNumber">Item #{problemItemDetails.ItemNumber}</div>
                                            <div className="itemInventory"><strong>{problemItemDetails.Inventory} available</strong></div>
                                            <div className="reason">
                                                <p>REASON:</p>
                                                <select
                                                    required
                                                    id='problemReason'
                                                    value={formFields.problemReason}
                                                    onChange={onFieldChangeHandler}
                                                >
                                                    <option id="0" value="">Replacement Reason</option>
                                                    <option id="5" value="5">Defective Out of Box</option>
                                                    <option id="6" value="6">Expected Wear and Tear</option>
                                                    <option id="7" value="7">Improper Usage</option>
                                                    <option id="8" value="8">Mispack</option>
                                                    <option id="9" value="9">Non-Warranty Case</option>
                                                    <option id="10" value="10">Unexpected Failure</option>
                                                    <option id="11" value="11">User Expectations</option>
                                                    <option id="12" value="12">Shipping Issue</option>
                                                    <option id="13" value="13">Pawl Failure</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
                <div id='replacementItemsDetails' className='orderFormSection itemSection' >
                    <div className='orderFormSectionTitle'>Replacement Item(s)</div>
                    <div className='sectionContent'>
                        <div className="itemDetailsSection">
                            {renderReplacementItems(
                                replacementItems,
                                itemDataList,
                                onReplacementItemNumberChangeHandler,
                                onReplacementItemQtyChangeHandler,
                                onReplacementItemIDChangeHandler,
                                removeReplacementItem
                            )}
                        </div>
                        <div className="buttonsRow">
                            <div>
                                <button className="addRemoveItem" id="addItem" onClick={addReplacementItem}>
                                    <FontAwesomeIcon id="addItemIcon" icon={faPlus} />
                                    <p id="addItemText">Add another item</p>
                                </button>
                            </div>
                            {!props.hasPendingOrder ?
                                <button id='submitButton' type="submit">SUBMIT ORDER</button>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </form>
    ;

    const orderSummary = collapsed ? null :
        <form id="caseOrderForm" className="caseOrderForm" onSubmit={handleSubmit} >
            <hr />
            <div>
                {minutesRemaining >= 0 ? <p>Order submitted. You can still <button type="button" id="cancelOrder" onClick={cancelOrder}>cancel</button> this order for {minutesRemaining} minutes and {secondsRemaining} seconds.</p> : <p>Order is pending and no longer available for cancellation.</p>}
            </div>
            <div id="endUserInfo" className='orderFormSection'>
                <div className='orderFormSectionTitle'>Customer Information</div>
                <div className='sectionContent'>
                    <div className='userDetails'>
                        <p>
                            <strong>Name: </strong>{formFields.customerFirstName} {formFields.customerLastName}
                            <br />
                            <strong>Email: </strong>{formFields.emailAddress}
                        </p>
                    </div>
                    <div className='userAddress'>
                        <div className="userAddressRow">
                            <div className="input-box">
                                <small className="input-label">Address Line 1</small>
                                <input type='text'
                                    id='addressLine1'
                                    placeholder='Address Line 1'
                                    value={formFields.addressLine1}
                                    onChange={onFieldChangeHandler}
                                    required
                                    readOnly
                                />
                            </div>
                            <div className="input-box">
                                <small className="input-label">Address Line 2</small>
                                <input type='text'
                                    id='addressLine2'
                                    placeholder='Address Line 2'
                                    value={formFields.addressLine2}
                                    onChange={onFieldChangeHandler}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="userAddressRow">
                            <div className="input-box">
                                <small className="input-label">City</small>
                                <input
                                    type='text'
                                    id='city'
                                    placeholder='City'
                                    value={formFields.city}
                                    onChange={onFieldChangeHandler}
                                    required
                                    readOnly
                                />
                            </div>
                            <div className="input-box">
                                <small className="input-label">State</small>
                                <select
                                    required
                                    id='state'
                                    value={formFields.state}
                                    onChange={onFieldChangeHandler}
                                    disabled
                                >
                                    { formFields.country === 'CA' ? provinceOptions : stateOptions }
                                </select>
                            </div>
                        </div>
                        <div className="userAddressRow">
                            <div className="input-box">
                                <small className="input-label">Country</small>
                                <select
                                    required
                                    id='country'
                                    value={formFields.country}
                                    onChange={onFieldChangeHandler}
                                    disabled
                                >
                                    <option value="US">United States</option>
                                    <option value="CA">Canada</option>
                                </select>
                            </div>
                            <div className="input-box">
                                <small className="input-label">Zip Code</small>
                                <input type='text'
                                    id='zipCode'
                                    placeholder='Zip'
                                    value={formFields.zipCode}
                                    onChange={onFieldChangeHandler}
                                    required
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="itemsRow">
                <div id="problemItemInfo" className='orderFormSection itemSection'>
                    <div className='orderFormSectionTitle'>Problem Item</div>
                    <div className='sectionContent'>
                        <div className="itemDetailsSection">
                            <div className="input-box">
                                <small className="input-label">Item Number</small>
                                <input
                                    list="items"
                                    id="problemItemNumber"
                                    placeholder="Item Number"
                                    value={formFields.problemItemNumber}
                                    onChange={onFieldChangeHandler}
                                    readOnly
                                />
                            </div>
                            {formFields.problemItemNumber && problemItemDetails.Description ?
                                <div className="itemDetailsRow">
                                    <div className="itemDetails">
                                        <a href={problemItemUrl} target="_blank" rel="noreferrer"><img className="itemImage" src={problemItemImage} /></a>
                                    </div>
                                    <div className="itemDetails">
                                        <div className="itemDescription">{problemItemDetails.Description}</div>
                                        <div className="itemNumber">Item #{problemItemDetails.ItemNumber}</div>
                                        <div className="itemInventory"><strong>{problemItemDetails.Inventory} available</strong></div>
                                        <div className="reason">
                                            <p>REASON:</p>
                                            <select
                                                required
                                                id='problemReason'
                                                value={formFields.problemReason}
                                                onChange={onFieldChangeHandler}
                                                disabled
                                            >
                                                <option id="0" value="">Replacement Reason</option>
                                                <option id="5" value="5">Defective Out of Box</option>
                                                <option id="6" value="6">Expected Wear and Tear</option>
                                                <option id="7" value="7">Improper Usage</option>
                                                <option id="8" value="8">Mispack</option>
                                                <option id="9" value="9">Non-Warranty Case</option>
                                                <option id="10" value="10">Unexpected Failure</option>
                                                <option id="11" value="11">User Expectations</option>
                                                <option id="12" value="12">Shipping Issue</option>
                                                <option id="13" value="13">Pawl Failure</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
                <div id='replacementItemsDetails' className='orderFormSection itemSection' >
                    <div className='orderFormSectionTitle'>Replacement Item(s)</div>
                    <div className='sectionContent'>
                        <div className="itemDetailsSection">
                            {renderReplacementItems(
                                replacementItems,
                                itemDataList,
                                onReplacementItemNumberChangeHandler,
                                onReplacementItemQtyChangeHandler,
                                onReplacementItemIDChangeHandler,
                                removeReplacementItem,
                                true,
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    ;


    return (
        <div className="caseOrder">
            <ErrorPopUp showErrorMessage={showErrorMessage}/>
            <div className="caseOrderHeader">
                <FontAwesomeIcon id="collapseButton" onClick={collapseButtonHandler} icon={collapseIcon} />
                <h3 id="orderHeaderText">Order Form</h3>
            </div>
            {props.hasPendingOrder ? orderSummary : orderForm}
            {qualifiesForRewards === 1 ?
                <div id='giveRewards' className='rewardsSection' >
                    <div className="rewardsInput">
                        <div id="existingRewards">
                            {caseProperties.RewardsAddedToCustomer > 0 ?
                                <p><b>${caseProperties.RewardsAddedToCustomer}</b> in rewards have already been applied to this case</p>
                                : null}
                        </div>
                        <div id="newRewards">
                            <p>Give Rewards: </p>
                            <input
                                type="number"
                                min="1"
                                id='amount'
                                value={rewardsInstead}
                                onChange={rewardsChangeHandler}
                                // required
                            />
                            <button id="giveRewardsButton" onClick={addRewardsToAccount}>
                  Add to account
                            </button>
                        </div>
                    </div>
                </div>
                : null}
        </div>
    );
}

export default CaseOrder;

const renderAlternateItems = (altItems, swapFunction) => {

    var numItems = altItems.length;
    // limit to 3 items per row
    var rowCount = Math.ceil(numItems / 3);
    var buttons = altItems.map( (item) =>
        <button className="altItemNumber" key={item} id={item} value={item} onClick={swapFunction}>{item}</button>
    );

    var rows = [];
    var numIndex = 0;
    for(var i = 0; i < rowCount; i++) {
        rows.push(
            <div className="altItemRow" key={i}>
                {buttons[numIndex]}
                {buttons[numIndex + 1]}
                {buttons[numIndex + 2]}
            </div>
        );
        numIndex = numIndex + 3;
    }

    return (
        <div className="altItemsContainer">
            {rows}
        </div>
    );


};

const renderReplacementItems = (replacementItems, itemDataList, onReplacementItemNumberChangeHandler, onReplacementItemQtyChangeHandler, onReplacementItemIDChangeHandler, removeReplacementItem, viewOnly) => {
    return replacementItems.map( (replacementItem, iterationIndex) =>
        <CaseOrderItem
            // formFields={formFields}
            key={iterationIndex}
            itemDataList={itemDataList}
            onItemNumberChangeHandler={onReplacementItemNumberChangeHandler}
            onItemQtyChangeHandler={onReplacementItemQtyChangeHandler}
            onItemIDChangeHandler={onReplacementItemIDChangeHandler}
            replacementIndex={iterationIndex}
            replacementItem={replacementItem}
            removeOrderItem={removeReplacementItem}
            viewOnly={viewOnly}
        />);
};

// If the country doesn't already exist on the case, use the zip to determine what
// should be prefilled. A zip with 6 (or more) characters, or including a letter can
// be determined as Canadian
const determineCountry = (zipCode) => {
    if (!zipCode) {
        return '';
    }

    if (zipCode.length >= 6 && /[a-zA-Z]+/.test(zipCode)) {
        return 'CA';
    }

    return 'US';
};
