import React from 'react';
import $ from 'jquery';
import '../css/CaseProperties.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faChevronCircleUp, faCog, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import EditPropertiesModal from '../NewCase/EditPropertiesModal';
import useCollapsable from '../customHooks/useCollapsable';
import getBaseUrl from '../BaseUrl';
import NetSuitePropertyMap from '../NetSuitePropertyMap';
import { withRouter } from 'react-router-dom';
import ErrorPopUp from '../ErrorPopUp';
import ReplacementReasonDropdown from '../NewCase/ReplacementReasonDropDown';
import { updateCaseReplacementReason } from '../Utils';

function CaseProperties(props) {
    const caseProperties = props.caseProperties;
    const [collapsed, toggleCollapsed] = useCollapsable(props.isConversation);
    const [showEditableProperties, toggleShowEditableProperties] =
      useCollapsable(false);
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);
    const itemDataList = props.itemDataList;

    const baseUrl = getBaseUrl(props.environment);

    function updateReplacementReason(replacementReasonValue) {
        updateCaseReplacementReason(props.selectedCase, replacementReasonValue, setShowErrorMessage);
    }

    const deleteCaseHandler = () => {
        if (!window.confirm('This will permanently delete the case in TST and NetSuite. Are you sure you want to do this?')) {
            return;
        }

        const requestBody = {
            caseId: props.selectedCase,
            status: 'DELETED'
        };

        $.ajax({
            url: '/api/case/updateStatus',
            method: 'PUT',
            data: requestBody
        }).done(() => {
            props.setSelectedCase(null);
            props.updateCaseList();
            props.history.push('/');
        }).fail((error) => {
            console.error('Error deleting the case: ' + JSON.stringify(error));
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
        });
    };

    const subject = caseProperties.CaseIssue === '1' ? 'Warranty Support' : caseProperties.CaseIssue;
    const propertiesContent = collapsed ? null :
        <span id="propertiesContentContainer">
            {caseProperties.AddrOne ?
                <div id="addressContainer">
                    <h3 id="addressHeader">Address</h3>
                    <p className="addressDetails">
                        {caseProperties.CustomerFirstName} {caseProperties.CustomerLastName}<br />
                        {caseProperties.AddrOne}<br />
                        {caseProperties.AddrTwo}<br />
                        {caseProperties.City}, {caseProperties.State} {caseProperties.ZipCode}
                    </p>
                </div> : null }
            <div id="propertiesContent">
                <p className="caseProperty"><b>Name:</b><a href={baseUrl + '/app/common/entity/custjob.nl?id=' + caseProperties.CustomerNetSuiteID + '&whence='} target="_blank" rel="noopener noreferrer"> {caseProperties.CustomerFirstName} {caseProperties.CustomerLastName}</a></p>
                <p className="caseProperty"><b>Phone Number:</b> {caseProperties.PhoneNumber}</p>
                <p className="caseProperty"><b>Subject:</b> {subject}</p>
                <ReplacementReasonDropdown setCaseIssue={updateReplacementReason} defaultValue={caseProperties.ReplacementReason}/>
                <p className="caseProperty"><b>Date Created:</b> {caseProperties.DateCreated}</p>
                <p className="caseProperty"><b>Status:</b> {NetSuitePropertyMap.STATUS_MAP.get(caseProperties.Status)}</p>
                <p className="caseProperty"><b>Assignee:</b> {caseProperties.AssigneeFirstName} {caseProperties.AssigneeLastName}</p>
                <p className="caseProperty"><b>Item:</b> {caseProperties.ItemNumber || 'Not Specified'}</p>
                <p className="caseProperty"><b>Cases Past Year:</b> {caseProperties.CaseCount}</p>
                <p className="caseProperty">
                    <b>NetSuite ID: </b>
                    <a href={baseUrl + '/app/crm/support/supportcase.nl?id=' + caseProperties.NetSuiteID + '&whence='} target="_blank" rel="noopener noreferrer">{caseProperties.ExternalID ? caseProperties.ExternalID : caseProperties.NetSuiteID}</a>
                </p>
                {caseProperties.RelatedSalesOrderNetSuiteID ?
                    <p className="caseProperty">
                        <b>Related Sales Order: </b>
                        <a href={baseUrl + '/app/accounting/transactions/salesord.nl?id=' + caseProperties.RelatedSalesOrderNetSuiteID + '&whence='} target="_blank" rel="noopener noreferrer">{caseProperties.RelatedPurchaseOrderID ? caseProperties.RelatedPurchaseOrderID : caseProperties.RelatedSalesOrderNetSuiteID}</a>
                    </p> : null}
                <span id="deleteButtonContainer">
                    <button
                        type="button"
                        id="deleteCaseButton"
                        className="standardButton"
                        onClick={deleteCaseHandler}
                    >
                        <FontAwesomeIcon id="warningIcon" icon={faExclamationTriangle} />
              DELETE CASE
                    </button>
                </span>
            </div>

        </span>
  ;

    const closeCaseClickHandler = () => {
        if (!window.confirm('Are you sure you want to close this case?')) {
            return;
        }

        const requestBody = {
            caseId: props.selectedCase,
            status: 5
        };

        $.ajax( {
            url: '/api/case/updateStatus',
            method: 'PUT',
            data: requestBody
        }).done(() => {
            props.updateRefreshCount();
            props.updateCaseList();
        }).fail( (error) => {
            console.error(error);
            setShowErrorMessage(true);
            setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
        });
    };

    const pendingUserResponseCaseClickHandler = () => {
    // if (!window.confirm("Are you sure you want to close this case?")) {
    //   return;
    // }

        const requestBody = {
            caseId: props.selectedCase,
            status: 6
        };

        $.ajax( {
            url: '/api/case/updateStatus',
            method: 'PUT',
            data: requestBody
        }).done(() => {
            props.updateRefreshCount();
            props.updateCaseList();
        }).fail( (error) => {
            console.error(error);
            setShowErrorMessage(true);
            setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
        });
    };

    const collapseIcon = collapsed ? faChevronCircleDown : faChevronCircleUp;

    return (
        <div className="casePropertiesContainer">
            <ErrorPopUp showErrorMessage={showErrorMessage} />
            {renderEditPropertiesModal(showEditableProperties,
                toggleShowEditableProperties, props.updateCaseList,
                props.setSelectedCase, caseProperties, props.selectedCase,
                props.updateRefreshCount, itemDataList)}
            <div className="caseProperties">
                <div className="casePropertiesHeader">
                    <FontAwesomeIcon
                        id="collapseButton"
                        onClick={toggleCollapsed}
                        icon={collapseIcon}
                    />
                    <h3 id="propertiesSectionHeader">Details</h3>
                    {renderEditPropertiesButton(showEditableProperties,
                        toggleShowEditableProperties, closeCaseClickHandler, pendingUserResponseCaseClickHandler)}
                </div>
                {propertiesContent}
            </div>
        </div>
    );
}

export default withRouter(CaseProperties);

const renderEditPropertiesButton = (showEditableProperties,
    toggleShowEditableProperties, closeCaseHandler, pendingUserResponseCaseHandler) => {
    const editPropertiesClickHandler = () => {
        if (!showEditableProperties) {
            toggleShowEditableProperties();
        }
    };

    return (
        <div id="caseButtonsContainer">
            <button id="pendingUserResponseCaseButton" className="standardButton" onClick={pendingUserResponseCaseHandler}>PEND&nbsp;RES</button>
            <button id="closeCaseButton" className="standardButton" onClick={closeCaseHandler}>CLOSE</button>
            <FontAwesomeIcon
                id="editPropertiesButton"
                icon={faCog}
                onClick={editPropertiesClickHandler}
            />
        </div>
    );
};

const renderEditPropertiesModal = (showEditableProperties, toggleShowEditableProperties, updateCaseList,
    setSelectedCase, caseProperties, selectedCase,
    updateRefreshCount, itemDataList) => {

    if (!showEditableProperties) {
        return null;
    }

    const currentProperties = {
        subject: caseProperties.CaseIssue || '',
        replacementReason: caseProperties.ReplacementReason || '',
        customerFirstName: caseProperties.CustomerFirstName || '',
        customerLastName: caseProperties.CustomerLastName || '',
        emailAddress: caseProperties.EmailAddress || '',
        itemNumber: caseProperties.ItemNumber || '',
        addressLine1: caseProperties.AddrOne || '',
        addressLine2: caseProperties.AddrTwo || '',
        city: caseProperties.City || '',
        state: caseProperties.State || '',
        country: caseProperties.Country || '',
        zipCode: caseProperties.ZipCode || '',
        id: selectedCase
    };

    return <EditPropertiesModal
        key={'editCase'}
        showNewCaseModal={showEditableProperties}
        toggleShowNewCaseModal={toggleShowEditableProperties}
        editMode={true}
        currentProperties={currentProperties}
        updateRefreshCount={updateRefreshCount}
        updateCaseList={updateCaseList}
        itemDataList={itemDataList}
        setSelectedCase={setSelectedCase}
    />;
};
