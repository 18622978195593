import React from 'react';

export default function useCollapsable(initialValue) {
    const [collapsed, setCollapsed] = React.useState(initialValue);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    return [collapsed, toggleCollapsed];
}

