import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import useCollapsable from '../customHooks/useCollapsable';
import LoadingIndicator from '../LoadingIndicator';

export default function MessageListLoading() {
    const [collapsed, toggleCollapsed] = useCollapsable(false);
    const collapsedIcon = collapsed ? faChevronCircleDown : faChevronCircleUp;
    return (
        <div className="caseMessageList">
            <div className="messageListHeader">
                <FontAwesomeIcon
                    id="collapseButton"
                    icon={collapsedIcon}
                    onClick={toggleCollapsed}
                />
                <h3 id="messageHeader">Messages</h3>
            </div>
            <LoadingIndicator />
        </div>
    );
}