import React from 'react';
import $ from 'jquery';
import '../css/EditPropertiesModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ErrorPopUp from '../ErrorPopUp';
import {
    validationPatterns
} from '../Utils';
import NetSuiteAddressSelector from '../NetSuiteAddressSelector';
import ReplacementReasonDropDown from './ReplacementReasonDropDown';
import useDebouncedState from '../customHooks/useDebouncedState';

const validatedBackgroundColor = 'lightgray';
const invalidColor = 'red';
const validatedTextColor = 'black';

const emptyFieldsObject = {
    replacementReason: '',
    message: '',
    imageUrls: [],
    customerFirstName: '',
    customerLastName: '',
    emailAddress: '',
    itemNumber: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    country: '',
    zipCode: ''
};

export default function EditPropertiesModal(props) {
    const [fields, debouncedFields, setFields] = useDebouncedState(Object.assign(emptyFieldsObject, props.newCaseData ? {
        customerFirstName: props.newCaseData.firstname,
        customerLastName: props.newCaseData.lastname,
        message: props.newCaseData.body,
        emailAddress: props.newCaseData.sender
    } : {}));
    const [submitting, setSubmitting] = React.useState(false);
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);
    const itemDataList = props.itemDataList;

    React.useEffect(() => {
        if (props.editMode) {
            setFields(props.currentProperties);
        }
    }, [props.editMode, props.currentProperties]);

    const options = React.useMemo(() => {
        return itemDataList.map(item => <option key={item}>{item}</option>);
    }, [itemDataList]);

    const setReplacementReason = (newReplacementReason) => {
        const newFields = {...fields};
        newFields.replacementReason = newReplacementReason;
        setFields(newFields);
    };

    const onFieldChangeHandler = (event) => {
        const newFields = {...fields};
        newFields[event.currentTarget.id] = event.currentTarget.value;
        setFields(newFields);

        const currentLabel = $('#' + event.currentTarget.id + 'Label');
        if (event.currentTarget.value) {
            currentLabel.css('visibility', 'visible');
        } else {
            currentLabel.css('visibility', 'hidden');
        }
        if (event.currentTarget.id === 'itemNumber') {
            validateItemNumber(event.currentTarget.value, itemDataList);
        } else {
            const borderColor = event.currentTarget.value
                ? validatedBackgroundColor : invalidColor;
            const textColor = event.currentTarget.value
                ? validatedTextColor : invalidColor;
            $('#' + event.currentTarget.id + 'Container').css('border', '1px solid ' + borderColor);
            currentLabel.css('color', textColor);
        }
    };

    const submitClickHandler = () => {
        if (!validateRequiredFields(fields, itemDataList)) {
            return;
        }

        const requestBody = {
            // Warranty support
            CaseIssue: 1,
            ReplacementReason: fields.replacementReason,
            ItemNumber: fields.itemNumber,
            AddrOne: fields.addressLine1,
            AddrTwo: fields.addressLine2,
            City: fields.city,
            State: fields.state,
            Country: fields.country,
            ZipCode: fields.zipCode,
            // Not Started
            Status: 1,
            DateCreated: new Date().toLocaleString(),
            Message: fields.message,
            Customer: {
                FirstName: fields.customerFirstName,
                LastName: fields.customerLastName,
                EmailAddress: fields.emailAddress,
                // Good
                SupportStatus: 1
            }
        };

        setSubmitting(true);
        new Promise((resolve, reject) => {
            if (props.editMode) {
                $.ajax({
                    url: '/api/case/editProperties/' + props.currentProperties.id,
                    method: 'PUT',
                    data: JSON.stringify(requestBody),
                    contentType: 'application/json'
                }).then(resolve, reject);
            } else {
                // Append address information to the customer message
                // similar to how support forms pass in address data
                // since this data is being processed directly by NetSuite
                if (requestBody.AddrOne) {
                    requestBody.Message += [
                        '\nShipping Address: ' + requestBody.AddrOne,
                        'Shipping Address 2: ' + requestBody.AddrTwo,
                        'Shipping City: ' + requestBody.City,
                        'Shipping State: ' + requestBody.State,
                        'Shipping Zip: ' + requestBody.ZipCode
                    ].join('\n');
                }

                $.ajax({
                    url: '/api/case/createNewCaseInNetSuite/',
                    method: 'POST',
                    data: JSON.stringify(requestBody),
                    contentType: 'application/json'
                }).then(resolve, reject);
            }
        })
            .then(() => {
                props.toggleShowNewCaseModal();
                props.updateRefreshCount();
            })
            .catch(error => {
                setSubmitting(false);
                console.error(JSON.stringify(error));
                setShowErrorMessage(true);
                setTimeout(() => {
                    setShowErrorMessage(false);
                }, 5000);
            });
    };

    const onAddressChange = address => {
        setFields({
            ...fields,
            addressLine1: address.addr1,
            addressLine2: address.addr2 || '',
            city: address.city,
            state: address.state,
            country: address.country,
            zipCode: address.zip,
        });
    };

    const header = props.editMode ? 'Update Case' : 'New Case';
    return (
        <div id="modalContainer">
            <div id="modalOverlay" />
            <div id="newCaseModal">
                <FontAwesomeIcon id="closeModalButton" onClick={props.toggleShowNewCaseModal} icon={faTimes} />
                <ErrorPopUp showErrorMessage={showErrorMessage}/>
                <h1 id="modalHeader">{header}</h1>
                <div id="caseIssueSection" className="newCaseSection">
                    <h2 className="newCaseSectionHeader"><b>Case Issue</b></h2>
                    <div className="sectionFields">
                        <div id="replacementReasonModalContainer">
                            <ReplacementReasonDropDown
                                setCaseIssue={setReplacementReason}
                                caseIssue={fields.replacementReason}
                            />
                        </div>
                    </div>
                </div>
                {renderCustomerSection(props.editMode, fields, onFieldChangeHandler)}
                <div id="warrantySection" className="newCaseSection">
                    <h2 className="newCaseSectionHeader"><b>Warranty Information</b></h2>
                    <div className="sectionFields">
                        <div className="sectionField" id="itemNumberContainer">
                            <p className="fieldLabel" id="itemNumberLabel">Item Number</p>
                            <input
                                list="items"
                                className="fieldValue"
                                id="itemNumber"
                                placeholder="Item Number"
                                value={fields.itemNumber}
                                onChange={onFieldChangeHandler}
                            />
                            <datalist id="items">
                                {options}
                            </datalist>
                        </div>
                        <div className="sectionField" style={{visibility: 'hidden'}} />

                        {debouncedFields.emailAddress.match(validationPatterns.email) ?
                            <div className="sectionContainer">
                                <NetSuiteAddressSelector
                                    email={debouncedFields.emailAddress}
                                    onAddressChange={onAddressChange}
                                    currentAddress={{
                                        addr1: fields.addressLine1,
                                        addr2: fields.addressLine2,
                                        city: fields.city,
                                        state: fields.state,
                                        country: fields.country,
                                        zip: fields.zipCode
                                    }}
                                />
                            </div> :
                            null
                        }

                        <div className="sectionField">
                            <p className="fieldLabel" id="addressLine1Label">Address</p>
                            <input
                                type="text"
                                className="fieldValue"
                                id="addressLine1"
                                placeholder="Address"
                                value={fields.addressLine1}
                                onChange={onFieldChangeHandler}
                            />
                        </div>
                        <div className="sectionField">
                            <p className="fieldLabel" id="addressLine2Label">Address (Line 2)</p>
                            <input
                                type="text"
                                className="fieldValue"
                                id="addressLine2"
                                placeholder="Address (Line 2)"
                                value={fields.addressLine2}
                                onChange={onFieldChangeHandler}
                            />
                        </div>
                        <div className="sectionField">
                            <p className="fieldLabel" id="cityLabel">City</p>
                            <input
                                type="text"
                                className="fieldValue"
                                id="city"
                                placeholder="City"
                                value={fields.city}
                                onChange={onFieldChangeHandler}
                            />
                        </div>
                        <div className="sectionField">
                            <p className="fieldLabel" id="stateLabel">State/Province</p>
                            <input
                                type="text"
                                className="fieldValue"
                                id="state"
                                placeholder="State/Province"
                                value={fields.state}
                                onChange={onFieldChangeHandler}
                            />
                        </div>
                        <div className="sectionField">
                            <p className="fieldLabel" id="countryLabel">Country</p>
                            <input
                                type="text"
                                className="fieldValue"
                                id="country"
                                placeholder="Country"
                                value={fields.country}
                                onChange={onFieldChangeHandler}
                            />
                        </div>
                        <div className="sectionField">
                            <p className="fieldLabel" id="zipCodeLabel">Zip Code</p>
                            <input
                                type="text"
                                className="fieldValue"
                                id="zipCode"
                                placeholder="Zip Code"
                                value={fields.zipCode}
                                onChange={onFieldChangeHandler}
                            />
                        </div>
                    </div>
                </div>
                {props.editMode ?
                    null :
                    <div>
                        <h2 className="newCaseSectionHeader"><b>Customer Message</b></h2>
                        <textarea
                            className="textFieldValue"
                            id="message"
                            onChange={onFieldChangeHandler}
                            value={fields.message}
                        />
                        {props.newCaseData && props.newCaseData.hasImages ?
                            <div id="image-placeholder"></div> :
                            null}
                    </div>}
                <div id="submitButtonContainer">
                    <button
                        disabled={submitting}
                        id="submitNewCase"
                        className="standardButton"
                        onClick={submitClickHandler}>
                        {submitting ? 'PROCESSING...' : `${props.editMode ? 'UPDATE' : 'CREATE'} CASE`}
                    </button>
                </div>
            </div>
        </div>
    );
}

const validateRequiredFields = (fields, itemList) => {
    const fieldsToValidate = [
        {
            fieldId: 'customerFirstName',
            fieldValue: fields.customerFirstName
        },
        {
            fieldId: 'customerLastName',
            fieldValue: fields.customerLastName
        },
        {
            fieldId: 'emailAddress',
            fieldValue: fields.emailAddress
        }
    ];

    let validatedTextFields = true;
    for (const validationFields of fieldsToValidate) {
        if (!validateIndividualField(validationFields.fieldId,
            validationFields.fieldValue)) {
            validatedTextFields = false;
        }
    }

    return validateItemNumber(fields.itemNumber, itemList) && validatedTextFields;
};

const validateIndividualField = (fieldId, fieldValue) => {
    if (!fieldValue) {
        $('#' + fieldId + 'Container').css('border', '1px solid red');
        $('#' + fieldId + 'Label').css('color', 'red');
    }

    return Boolean(fieldValue);
};

const validateItemNumber = (itemNumber, itemList) => {
    const validated = itemNumber === '' || itemList.includes(itemNumber);

    $('#itemNumberContainer').css('border', '1px solid ' +
      (validated ? validatedBackgroundColor : invalidColor));
    $('#itemNumberLabel').css('color', validated ? validatedTextColor : invalidColor);

    return validated;
};

const renderCustomerSection = (isEditMode, fields, onFieldChangeHandler) => {
    if (isEditMode) {
        return null;
    }

    return (
        <div id="customerSection" className="newCaseSection">
            <h2 className="newCaseSectionHeader"><b>Customer Information</b></h2>
            <div className="sectionFields">
                <div className="sectionField" id="customerFirstNameContainer">
                    <p className="fieldLabel" id="customerFirstNameLabel">First Name</p>
                    <input type="text"
                        className="fieldValue"
                        id="customerFirstName"
                        placeholder="First Name"
                        value={fields.customerFirstName}
                        onChange={onFieldChangeHandler}
                    />
                </div>
                <div className="sectionField" id="customerLastNameContainer">
                    <p className="fieldLabel" id="customerLastNameLabel">Last Name</p>
                    <input type="text"
                        className="fieldValue"
                        id="customerLastName"
                        placeholder="Last Name"
                        value={fields.customerLastName}
                        onChange={onFieldChangeHandler}
                    />
                </div>
                <div className="sectionField" id="emailAddressContainer">
                    <p className="fieldLabel" id="emailAddressLabel">Email Address</p>
                    <input type="email"
                        className="fieldValue"
                        id="emailAddress"
                        placeholder="Email Address"
                        value={fields.emailAddress}
                        onChange={onFieldChangeHandler}
                    />
                </div>
            </div>
        </div>
    );
};
