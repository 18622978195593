import React from 'react';


// Stores a collection of values without duplicates
export default function useSet(initialValue = [], getIdentifier = item=>item, sortFunction) {
    const objectFromArray = items => {
        return items.reduce((object, item) => {
            object[getIdentifier(item)] = item;
            return object;
        }, {});
    };
    const cachedObject = React.useRef();
    if (!cachedObject.current) {
        cachedObject.current = objectFromArray(initialValue, getIdentifier);
    }
    const sortObjectToArray = object => {
        if (sortFunction) {
            return Object.values(Object.keys(object).sort((a, b) => sortFunction(object[a], object[b])).reduce(
                (obj, key) => {
                    obj[key] = object[key];
                    return obj;
                },
                {}
            ));
        }
        return Object.values(cachedObject.current);
    };

    const [collection, setCollection] = React.useState([]);

    const set = items => {
        cachedObject.current = objectFromArray(items);
        setCollection(sortObjectToArray(cachedObject.current));
    };

    const add = item => {
        cachedObject.current[getIdentifier(item)] = item;
        setCollection(sortObjectToArray(cachedObject.current));
    };

    const remove = item => {
        if (cachedObject.current[getIdentifier(item)]) {
            delete cachedObject.current[getIdentifier(item)];
            setCollection(Object.values(cachedObject.current));
        }
    };

    return [
        collection,
        add,
        remove,
        set
    ];
}
