import React from 'react';

export default function useOpenMenuEffect(currentlyOpenedMenu, openMenuValue, collapsed, toggleCollapsed) {
  React.useEffect(() => {
    if (currentlyOpenedMenu
        && currentlyOpenedMenu !== openMenuValue
        && !collapsed) {

      toggleCollapsed();
    }
  }, [currentlyOpenedMenu, openMenuValue, collapsed, toggleCollapsed]);
}