import React from 'react';

export default function ErrorPopUp(props) {
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);

    React.useEffect(() => {
        setShowErrorMessage(props.showErrorMessage);
    }, [props.showErrorMessage]);

    React.useEffect(() => {
        if (showErrorMessage) {
            setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
        }
    }, [showErrorMessage]);

    if (!showErrorMessage) {
        return null;
    }

    return <h3 id="errorMessagePopUp">Oops, something went wrong. Please contact an admin if the problem persists.</h3>;
}