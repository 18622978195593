import $ from 'jquery';

/**
 * A helper class for cancelling a request after a React component has unmounted
 */
export class CancelableRequest {
    /**
     * Creates a new CancelableRequest
     * @param request A thenable object (Promise, Deferred)
     */
    constructor(request) {
        this.request = request;
        this.promise = new Promise((resolve, reject) => {
            this.rejectCallback = () => reject('unmount');
            this.request.then(resolve, reject);
        });
    }

    /**
     * Adds a success and fail callback to the request
     * @param success The callback on a completed request
     * @param fail The callback on a failed request
     */
    then(success, fail) {
        this.promise.then(result => {
            success(result);
        }, error => {
            if (error !== 'unmount' && fail) {
                fail(error);
            }
        });
        return this;
    }

    /**
     * Prevents the request from executing any of the callbacks provided by the then method
     */
    cancel() {
        this.rejectCallback();
    }
}

export function updateCaseReplacementReason(selectedCase, replacementReason, setShowErrorMessage) {
    const requestBody = {
        replacementReason: replacementReason
    };

    $.ajax({
        url: '/api/case/updateReplacementReason/' + selectedCase,
        method: 'PUT',
        data: requestBody
    }).fail(() => {
        console.log('Failed to update the replacement reason');
        setShowErrorMessage(true);
        setTimeout(() => {
            setShowErrorMessage(false);
        }, 5000);
    });
}

// Common validation patterns
export const validationPatterns = {
    // Validation pattern grabbed from SCA
    // eslint-disable-next-line
    email: /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
};
