import React from 'react';
import '../css/PendingResponseFilter.scss';

export default function PendingResponseFilter(props) {
    const onClickHandler = (event) => {
        props.setPendingResponseFrom(event.currentTarget.id);
    };

    const buttons = [
        {
            id: 'waitingOnEmployee',
            count: props.pendingEmployeeResponseCount,
            text: 'INBOX'
        },
        {
            id: 'waitingOnCustomer',
            count: props.pendingCustomerResponseCount,
            text: 'SENT'
        },
        {
            id: 'pendingUserResponse',
            count: props.pendingUserResponseCount,
            text: 'PENDING'
        },
        {
            id: 'closedCases',
            count: props.closedCaseCount,
            text: 'CLOSED'
        },
    ];
    return (
        <span className="pendingResponseFilter">
            {buttons.map(({ id, text, count }) =>
                <button key={id} className={`pendingResponseButton${props.pendingResponseFrom === id ? ' selected' : ''}`}
                    id={id}
                    onClick={onClickHandler}
                >
                    {text}<br/>{count}
                </button>
            )}
        </span>
    );
}
