import $ from 'jquery';
import React from 'react';
import { CancelableRequest } from './Utils';

export default function MediaViewer({ mediaSid, s3File }) {
    const [imageUrl, setImageUrl] = React.useState('');

    React.useEffect(() => {
        if (mediaSid) {
            const cancelableRequest = new CancelableRequest($.get('/api/attachment/twilioMediaUrl/' + mediaSid)).then(
                (response) => {
                    setImageUrl(response.url);
                }, (e) => {
                    console.error('Error caught retrieving attachment:', e.statusText);
                });

            return () => cancelableRequest.cancel();
        }

        if (s3File) {
            setImageUrl('/api/attachment/s3/' + s3File);
        }
    }, [mediaSid, s3File]);
    return imageUrl ? <a href={imageUrl}><img src={imageUrl} className="liveChatMedia" /></a> : <div>Failed to fetch image</div>;
}
