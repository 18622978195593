/**
 * Add conversation listeners to a conversation
 *
 * @param conversation The conversation to add listeners to
 * @param listeners A list of listener objects with event and callback
 * @return {Function} Removes all listeners (useful for React.useEffects)
 */
export function addConversationListeners(conversation, listeners) {
    const removeListenerMethods = listeners.map(({ event, callback }) => {
        conversation.on(event, callback);
        return () => conversation.off(event, callback);
    });
    return () => {
        removeListenerMethods.forEach(method => method());
    };
}

/**
 * Get the expected value for unread messages from a conversation
 *
 * @param conversation A conversation
 */
export async function getUnreadMessagesCount(conversation) {
    try {
        if (conversation.status === 'joined' && conversation.state.current !== 'closed') {
            // if (conversation.channelState.status);
            const unreadMessagesCount = await conversation.getUnreadMessagesCount();
            // When a new conversation is created, the unread message count remains null until a message is read
            return unreadMessagesCount === null ? await conversation.getMessagesCount() : unreadMessagesCount;
        }
    } catch (error) {
        console.error('Failed to read message count', conversation, error);
    }

    return 0;
}

export const isSupportIdentity = identity => identity.match(/.*(\+TST|support)@tekton\.com$/);
export const isCustomerIdentity = identity => !isSupportIdentity(identity);
