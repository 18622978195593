/* eslint-disable indent */
import React from 'react';
import '../css/ChatListItem.scss';
import SenderIcon from '../CaseDetails/SenderIcon';
import { addConversationListeners, getUnreadMessagesCount, isCustomerIdentity } from '../ChatDetails/ChatUtils';
import useSet from '../customHooks/useSet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { CancelableRequest } from '../Utils';

export default function ChatListItem({
    isSelected,
    conversation,
    conversationsUser,
    onClick
}) {
    const [unreadMessages, setUnreadMessages] = React.useState(0);
    const [typingParticipants, addTypingParticipant, removeTypingParticipant] = useSet([], participant => participant.sid);
    const [participantNames, setParticipantNames] = React.useState({});
    const [assignee, setAssignee] = React.useState(conversation.attributes.assignee);

    React.useEffect(() => {
        const participantsRequest = new CancelableRequest(conversation.getParticipants())
            .then(participants => {
                Promise.all(participants.map(async participant => {
                    if (isCustomerIdentity(participant.identity)) {
                        return await participant.getUser();
                    }
                    return {
                        friendlyName: participant.identity.replace(/^(\w)(\w*).*$/i, (match, firstLetter, name) =>
                            `${firstLetter.toUpperCase()}${name}`
                        ),
                        identity: participant.identity
                    };
                }))
                    .then(users => users.reduce((data, user) => {
                        data[user.identity] = user.friendlyName || user.identity;
                        return data;
                    }, {}))
                    .then(setParticipantNames)
                    .catch(error => console.error('Failed to load participant names', conversation.sid, error));
            }, error => {
                console.error('Failed to load conversation participants', conversation.sid, error);
            });


        return () => {
            participantsRequest.cancel();
        };
    }, [conversation.sid]);

    React.useEffect(() => {
        if (isSelected) {
            setUnreadMessages(0);
            if (conversation.state.current !== 'closed') {
                conversation.setAllMessagesRead();
            }
        } else {
            getUnreadMessagesCount(conversation).then(setUnreadMessages);
        }

        const removeListeners = addConversationListeners(conversation, [
            {
                event: 'updated',
                callback: event => setAssignee(event.conversation.attributes.assignee)
            },
            {
                event: 'messageAdded',
                callback: message => {
                    if (isSelected) {
                        setUnreadMessages(0);
                        conversation.setAllMessagesRead();
                    } else if (message.author !== 'support@tekton.com') {
                        setUnreadMessages(count => count + 1);
                    }
                }
            },
            {
                event: 'typingStarted',
                callback: addTypingParticipant
            },
            {
                event: 'typingEnded',
                callback: removeTypingParticipant
            }
        ]);
        return () => {
            removeListeners();
        };
    }, [conversation.sid, isSelected]);

    const title = conversation.channelState.friendlyName || participantNames[conversation.createdBy] || conversation.createdBy;
    const isAssignedToSelf = conversationsUser && assignee === conversationsUser.identity;
    const assigneeName = isAssignedToSelf ? 'You' : participantNames[assignee];
    return (
        <div className={`caseListItem ${isSelected ? 'selected' : ''} ${unreadMessages ? 'pulse' : ''}`} onClick={onClick}>
            <SenderIcon senderName={title} id={isSelected ? 'selectedSenderIcon' : 'caseListSenderIcon'}/>
            <p className="chatListName"><b>{title}</b></p>
            {assignee ?
                <span className="chatListAssignee">
                    Assignee:
                    <span className={`${isAssignedToSelf ? 'assigned' : ''}`}> {assigneeName}</span>
                </span>
            : null}
            <div className="caseListDate">
                {unreadMessages ? <div>{unreadMessages} unread messages</div> : null}
                {typingParticipants.map((participant, index) =>
                    <span key={participant.sid}>{index ? ', ' : ''}{participantNames[participant.identity] || participant.identity}</span>
                )}
                {typingParticipants.length ? <span> is typing</span> : null}
            </div>
            <span className="caseListIcons">
                <span className={`chatStatusIcon ${conversation.state.current}`}>
                    <FontAwesomeIcon icon={faCircle} />
                </span>
            </span>
        </div>
    );
}
