import React from 'react';
import $ from 'jquery';
import CaseMessage from './CaseMessage';
import '../css/CaseMessageList.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faChevronCircleUp, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import useCollapsable from '../customHooks/useCollapsable';
import ErrorPopUp from '../ErrorPopUp';
import Select from '../Select';
import { CancelableRequest } from '../Utils';
import SenderIcon from './SenderIcon';

export default function CaseMessageList(props) {
    const [caseMessages, setCaseMessages] = React.useState([]);
    const [refreshMessages, setRefreshMessages] = React.useState(true);
    const [collapsed, toggleCollapsed] = useCollapsable(false);
    const [showAllMessages, setShowAllMessages] = React.useState(false);
    const [openedMessages, updateOpenedMessages] = useOpenedMessages();
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);
    const [username, setUsername] = React.useState('');
    const [newMessageText, setNewMessageText] = React.useState('');
    const [draftStatus, setDraftStatus] = React.useState('');

    React.useEffect(() => {
        setRefreshMessages(true);
    }, [props.updateCaseDetailsCount]);

    React.useEffect(() => {
        const request = new CancelableRequest($.get('/api/user/username'))
            .then(response => {
                setUsername(response);
            }, error => {
                console.error('Unable to retrieve the current username: '
              + JSON.stringify(error));
            });

        return () => request.cancel();
    }, []);

    React.useEffect(() => {
        const request = new CancelableRequest($.get('/api/draft/' + props.selectedCase))
            .then(response => {
                setNewMessageText(response.messageText);
            }, error => {
                console.log(JSON.stringify(error));
            });

        return () => request.cancel();
    }, [props.selectedCase]);

    React.useEffect(() => {
        // Allow for immediate invocation
        let messageRequest;
        if (refreshMessages) {
            messageRequest = new CancelableRequest($.get('/api/message/case/' + props.selectedCase))
                .then(response => {
                    setCaseMessages(response);
                    props.setMessageCount(response.length);
                    setRefreshMessages(false);
                }, error => {
                    console.error('Received error retrieving messages: '
                                  + JSON.stringify(error));
                });
        }
        // Setup an internval that refreshes the messages on an interval
        let intervalMessageRequest;
        const timer = setInterval(() => {
            intervalMessageRequest = new CancelableRequest($.get('/api/message/case/' + props.selectedCase))
                .then(response => {
                    setCaseMessages(response);
                    props.setMessageCount(response.length);
                }, error => {
                    console.error('Received error retrieving messages: '
                                  + JSON.stringify(error));
                });
        }, 60000);

        return () => {
            messageRequest && messageRequest.cancel();
            intervalMessageRequest && intervalMessageRequest.cancel();
            clearInterval(timer);
        };
    }, [props.selectedCase, refreshMessages]);

    const onTextChangeHandler = (event) => {
        const updatedText = event.currentTarget.value;
        setNewMessageText(updatedText);
        setDraftStatus('Saving Draft');

        // If the user has continued typing before the timeout has finished, reset
        // the timeout.
        if (window.typingTimeout) {
            clearTimeout(window.typingTimeout);
        }

        // This sets a new timeout for 750 milliseconds to update the draft table
        // when that time has passed without any user input
        window.typingTimeout = setTimeout(() => {
            const requestBody = {
                messageText: updatedText
            };
            $.ajax( {
                url: '/api/draft/updateDraft/' + props.selectedCase,
                method: 'PUT',
                data: requestBody
            }).done(() => {
                setDraftStatus('Draft Saved');
            }).fail((error) => {
                console.error(JSON.stringify(error));
                setDraftStatus('Draft Save Failed');
            });
        }, 750);
    };

    const showMoreMessagesCallback = () => {
        setShowAllMessages(true);
    };

    const toggleCollapseWithShowAllMessages = () => {
        toggleCollapsed();
        setShowAllMessages(false);
    };

    const sendMessageClickHandler = (event) => {

        if (!props.selectedCaseNetSuiteID) {
            console.error('NetSuiteID is not yet populated, cannot send message');
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
            return;
        }

        const messageText = $('#newMessageTextArea').val();
        if (!messageText) {
            return;
        }
        const sendInternal = event.currentTarget.id.includes('Internal');

        if (!sendInternal && !window.confirm('This will send to the end user. Are you sure you want to do this?')) {
            return;
        }

        const sendMessageBody = {
            CaseID: props.selectedCase,
            MessageText: messageText,
            InternalOnly: sendInternal
        };

        $.post('/api/message/sendMessage', sendMessageBody)
            .done( () => {
                setRefreshMessages(true);
                props.updateRefreshCount();
                setTimeout(() => props.updateCaseDetails(), 500);
                $('#newMessageTextArea').val('');
            })
            .fail( (error) => {
                console.error('Error when adding new message: ' + JSON.stringify(error));
                setShowErrorMessage(true);
                setTimeout(() => setShowErrorMessage(false), 5000);
            });

        $.ajax({
            url: '/api/draft/' + props.selectedCase,
            method: 'DELETE'
        }).done(() => {
            setNewMessageText('');
            setDraftStatus('');
        }).fail((error) => {
            console.log(JSON.stringify(error));
        });
    };

    const onTemplateChange = e => {
        const templateId = Number(e.target.value);
        setNewMessageText(props.emailTemplates.find(template => template.ID === templateId).Template);
    };
    const collapsedIcon = collapsed ? faChevronCircleDown : faChevronCircleUp;
    const messageListContent = collapsed ? null :
        <div id="messageListContent">
            <ErrorPopUp showErrorMessage={showErrorMessage} />
            {renderMessages(caseMessages, showAllMessages, showMoreMessagesCallback,
                openedMessages, updateOpenedMessages, props.environment, props.selectedCaseNetSuiteID, props.conversationSid)}
            <div className="newMessageContainer">
                <SenderIcon
                    id="newMessageSenderIcon"
                    senderName={username}
                    useMessageStyles={true}
                    internalOnly={false}
                />
                <textarea id="newMessageTextArea" placeholder="Type your message here..." value={newMessageText} onChange={onTextChangeHandler}/>
                <p id="draftStatusText">{draftStatus}</p>
                <div className="sendMessageButtons">
                    <button
                        className="messageButton" id="sendMessageExternal"
                        onClick={sendMessageClickHandler}
                    >
                        <FontAwesomeIcon id="warningIcon" icon={faExclamationTriangle} />
                        SEND EXTERNAL
                    </button>
                    <button
                        className="messageButton"
                        id="sendMessageInternal"
                        onClick={sendMessageClickHandler}
                    >
                        SAVE INTERNAL
                    </button>
                    <Select onChange={onTemplateChange} defaultValue="none">
                        <option value="none" disabled>-- Select Template --</option>
                        {props.emailTemplates.map(template =>
                            <option key={template.ID} value={template.ID}>{template.Title}</option>
                        )}
                    </Select>
                </div>
            </div>
        </div>
  ;

    return (
        <div className="caseMessageList">
            <div className="messageListHeader">
                <FontAwesomeIcon
                    id="collapseButton"
                    icon={collapsedIcon}
                    onClick={toggleCollapseWithShowAllMessages}
                />
                <h3 id="messageHeader">Messages</h3>
            </div>
            {messageListContent}
        </div>
    );
}

const renderMessages = (messageList, showAllMessages, showAllMessagesCallback, openedMessages, updateOpenedMessages, environment, selectedCaseNetSuiteId, conversationSid) => {
    if (messageList.length < 5 || showAllMessages) {
        return messageList.map( (message, index) => {
            return (
                <CaseMessage
                    key={message.ID}
                    selectedCaseNetSuiteId={selectedCaseNetSuiteId}
                    conversationSid={conversationSid}
                    message={message}
                    defaultOpened={openedMessages[message.ID] || index === messageList.length - 1}
                    updateOpenedMessages={updateOpenedMessages}
                    displayBorderBottom={true}
                    environment={environment}
                    index={index}
                />
            );
        });
    }
    const lastMessageIndex = messageList.length - 1;
    return (
        <div id="suppressedMessageList">
            <CaseMessage
                key={messageList[0].ID}
                selectedCaseNetSuiteId={selectedCaseNetSuiteId}
                conversationSid={conversationSid}
                message={messageList[0]}
                defaultOpened={openedMessages[messageList[0].ID]}
                updateOpenedMessages={updateOpenedMessages}
                environment={environment}
                index={0}
            />
            <div id="hiddenMessagesContainer">
                <p id="hiddenMessagesCount" onClick={showAllMessagesCallback}>
                    {messageList.length - 2}
                </p>
            </div>
            <CaseMessage
                key={messageList[lastMessageIndex].ID}
                selectedCaseNetSuiteId={selectedCaseNetSuiteId}
                conversationSid={conversationSid}
                message={messageList[lastMessageIndex]}
                defaultOpened={true}
                updateOpenedMessages={updateOpenedMessages}
                environment={environment}
                index={messageList.length - 1}
            />
        </div>
    );


};

function useOpenedMessages() {
    const [openedMessages, setOpenedMessages] = React.useState({});

    const updateOpenedMessages = (messageId) => {
        let updatedOpenMessages = openedMessages;

        if (updatedOpenMessages[messageId]) {
            updatedOpenMessages[messageId] = !updatedOpenMessages[messageId];
        } else {
            updatedOpenMessages[messageId] = true;
        }

        setOpenedMessages(updatedOpenMessages);
    };

    return [openedMessages, updateOpenedMessages];
}
