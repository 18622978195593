import React from 'react';
import $ from 'jquery';
import Select from './Select';
import { CancelableRequest } from './Utils';

const emptyAddress = {
    addr1: '',
    addr2: '',
    city: '',
    state: '',
    country: 'US',
    zip: '',
};

export default function NetSuiteAddressSelector({
    email,
    netsuiteId,
    currentAddress,
    onAddressChange,
}) {
    const [isLoading, setIsLoading] = React.useState(false);
    const [addresses, setAddresses] = React.useState([]);
    const [selectedAddress, setSelectedAddress] = React.useState('none');

    // Load the customer addresses either by email or NetSuite ID
    React.useEffect(() => {
        if (netsuiteId || email) {
            const identifier = netsuiteId ? `netsuiteId=${netsuiteId}` : `email=${encodeURIComponent(email)}`;
            setIsLoading(true);

            const request = new CancelableRequest($.get(`/api/user/addresses?${identifier}`))
                .then(response => {
                    setAddresses(response.items);
                    setIsLoading(false);
                }, error => {
                    console.error('Failed to load customer addresses: ', error);
                    setIsLoading(false);
                });

            return () => {
                request.cancel();
            };
        }
    }, [netsuiteId, email]);

    // Set the selected address if one matches from the current address data
    React.useEffect(() => {
        if (currentAddress) {
            const matchedAddress = addresses.find(address => [
                'addr1',
                'addr2',
                'city',
                'state',
                'country',
                'zip'
            ].reduce((isEqual, component) => {
                // Convert any undefined values to empty string
                return isEqual && (address[component] || '') === (currentAddress[component] || '');
            }, true));

            setSelectedAddress(matchedAddress ? matchedAddress.nkey : 'none');
        }
    }, [currentAddress, addresses]);

    const onSelectAddress = e => {
        e.preventDefault();

        const addressId = e.target.value;
        const newAddress = addresses.find(address => address.nkey === addressId);
        if (newAddress) {
            setSelectedAddress(addressId);
            onAddressChange(newAddress);
        } else{
            setSelectedAddress(addressId);
            onAddressChange(emptyAddress);
        }
    };

    if (!netsuiteId && !email) {
        return 'Please supply a NetSuite ID or email';
    }
    return (
        <Select onChange={onSelectAddress} value={selectedAddress}>
            {isLoading ?
                <option value="none" disabled>-- Loading --</option> :
                <option value="none" disabled>-- Select Address --</option>
            }
            <option value="empty"></option>
            {addresses.map(address => <option key={address.nkey} value={address.nkey}>{address.addr1}</option>)}
        </Select>
    );
}
