import DOMPurify from 'dompurify';
import $ from 'jquery';
import React from 'react';
import getBaseUrl from '../../BaseUrl';
import LoadingIndicator from '../../LoadingIndicator';
import { CancelableRequest } from '../../Utils';

const FILE_TYPE_MAP = {
    PNG: 'data:image/png;base64,',
    JPG: 'data:image/jpeg;base64,',
    GIF: 'data:image/gif;base64,',
};

export default function ExpandedMessage(props) {
    const baseUrl = getBaseUrl(props.environment);
    let messageText =
        props.message.MessageText &&
        props.message.MessageText.replace("<BASE href='https://3426199.app.netsuite.com/'>", '');

    if (messageText) {
        messageText = messageText.replace(
            /<br>Shipping Address:.*Shipping Address 2:.*Shipping City:.*Shipping State:.*Shipping Zip:.*/,
            ''
        );
    }
    const cleanHtml = DOMPurify.sanitize(messageText);
    const [attachmentInfo, setAttachmentInfo] = React.useState({});
    const [twilioAttachmentUrls, setTwilioAttachmentUrls] = React.useState([]);

    React.useEffect(() => {
        if (props.attachmentList && props.attachmentList.length > 0 && props.selectedCaseNetSuiteId) {
            // Check if the images are from S3
            if (props.attachmentList[0].AttachmentUrl.includes('/media/s3')) {
                // TODO: Refactor similar logic between MediaViewer and this component
                setAttachmentInfo({
                    images: props.attachmentList.map((attachment) => {
                        const name = attachment.AttachmentUrl.split('/').pop();
                        return {
                            contents: '/api/attachment/s3/' + name,
                            href: attachment.AttachmentUrl,
                            name,
                            fileTypeTag: '',
                        };
                    }),
                });
            } else {
                // Load images from NetSuite
                let url = '/api/attachment/bytes?caseId=' + props.selectedCaseNetSuiteId;
                if (props.index !== 0) {
                    url += '&messageId=' + props.message.NetSuiteID;
                }
                $.get(url)
                    .done((response) => {
                        if (!response.body || response.body === '') {
                            return;
                        }

                        const attachmentInfoList = JSON.parse(response.body);

                        if (attachmentInfoList.images) {
                            attachmentInfoList.images.forEach((attachment) => {
                                attachment.fileTypeTag = getAttachmentFileTypeTag(attachment.fileType);
                            });
                            setAttachmentInfo(attachmentInfoList);
                        }
                    })
                    .fail((error) => {
                        console.error('Failure to get attachments: ' + error);
                    });
            }
        }
    }, [props.attachmentList, props.index, props.message, props.selectedCaseNetSuiteId]);

    React.useEffect(() => {
        if (props.message.MediaSIDList) {
            const parsedMediaSidList = props.message.MediaSIDList.split(',');
            const requestArray = parsedMediaSidList.map((mediaSid) => {
                return $.get('/api/attachment/twilioMediaUrl/' + mediaSid);
            });

            const cancelableRequest = new CancelableRequest(Promise.all(requestArray)).then((attachments) => {
                setTwilioAttachmentUrls(attachments.map((attachment) => attachment.url));
            });

            return () => cancelableRequest.cancel();
        }
    }, [props.message]);

    const preventEventBubblingHandler = (event) => {
        event.stopPropagation();
    };

    return (
        <div className="expandedMessage" id={props.message.ID}>
            <div className="individualMessageContent">
                <p id="messageRecipient">
                    to: {props.message.RecipientFirstName} {props.message.RecipientLastName} (
                    {props.message.FormattedTimeSent})
                </p>
                <div
                    className="messageText"
                    onClick={preventEventBubblingHandler}
                    dangerouslySetInnerHTML={{ __html: cleanHtml }}
                ></div>
            </div>
            {attachmentInfo.images
                ? renderAttachments(attachmentInfo, preventEventBubblingHandler, baseUrl)
                : renderAttachmentPlaceholders(props.attachmentList, preventEventBubblingHandler, baseUrl)}
            {twilioAttachmentUrls.length
                ? renderTwilioAttachments(twilioAttachmentUrls, preventEventBubblingHandler)
                : null}
        </div>
    );
}

const renderAttachments = (attachmentInfo, preventEventBubblingHandler, baseUrl) => {
    if (!attachmentInfo.images) {
        return null;
    }

    return (
        <div id="attachments">
            {attachmentInfo.images.map((imageInfo, index) => {
                const href = imageInfo.href ? imageInfo.href : `${baseUrl}${imageInfo.url}&whence=`;
                return (
                    <a
                        id={index}
                        key={index}
                        href={href}
                        onClick={preventEventBubblingHandler}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="individualAttachment"
                    >
                        {imageInfo.name ? (
                            <p className="fileName">
                                <b>File Name:</b> {imageInfo.name}
                            </p>
                        ) : null}
                        {imageInfo.size ? (
                            <p className="fileSize">
                                {' '}
                                <b>| File Size:</b> {(imageInfo.size / 1000).toFixed(2)}KB
                            </p>
                        ) : null}
                        <br />
                        <img
                            alt="Attachment from user"
                            src={imageInfo.fileTypeTag + imageInfo.contents}
                            className="attachmentImage"
                        />
                    </a>
                );
            })}
        </div>
    );
};

const renderAttachmentPlaceholders = (attachmentList, preventEventBubblingHandler, baseUrl) => {
    return (
        <div id="attachments-placeholders">
            {attachmentList.map((attachmentInfo) => {
                return (
                    <a
                        id={attachmentInfo.ID}
                        key={attachmentInfo.ID}
                        href={baseUrl + attachmentInfo.AttachmentUrl}
                        onClick={preventEventBubblingHandler}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <LoadingIndicator />
                    </a>
                );
            })}
        </div>
    );
};

const getAttachmentFileTypeTag = (attachmentFileType) => {
    if (!attachmentFileType) {
        return '';
    }

    if (attachmentFileType.includes('JPG')) {
        return FILE_TYPE_MAP['JPG'];
    } else if (attachmentFileType.includes('GIF')) {
        return FILE_TYPE_MAP['GIF'];
    }

    return FILE_TYPE_MAP['PNG'];
};

const renderTwilioAttachments = (twilioAttachments, preventEventBubblingHandler) => {
    return twilioAttachments.map((attachmentUrl, index) => {
        return (
            <a
                id={index}
                key={index}
                href={attachmentUrl}
                onClick={preventEventBubblingHandler}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img alt="Attachment from user" src={attachmentUrl} className="attachmentImage" />
            </a>
        );
    });
};
