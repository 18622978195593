export default {
    STATUS_MAP: new Map([
        ['1', 'Not Started'],
        ['5', 'Closed'],
        ['6', 'Pending User Response'],
        ['11', 'Small Parts'],
        ['7', 'Fraud'],
        ['10', 'Waiting on Item'],
        ['2', 'In Progress'],
        ['3', 'Escalated'],
        ['4', 'Re-Opened']
    ]),

    SUPPORT_STATUS_MAP: {
        '1': 'Good',
        '2': 'Caution',
        '3': 'Fraud'
    },

    REPLACEMENT_REASON_MAP: {
        '5': 'Defective Out of Box',
        '6': 'Expected Wear and Tear',
        '7': 'Improper Usage',
        '8': 'Mispack',
        '9': 'Non-Warranty Case',
        '10': 'Unexpected Failure',
        '11': 'User Expectations',
        '12': 'Shipping Issue',
        '13': 'Pawl Failure'
    }
};
