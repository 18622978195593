import React from 'react';
import $ from 'jquery';
import '../css/CaseMessage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import useCollapsable from '../customHooks/useCollapsable';
import SenderIcon from './SenderIcon';
import MessagePreview from './Messages/MessagePreview';
import ExpandedMessage from './Messages/ExpandedMessage';
import formatDate from '../DateFormatter';
import { CancelableRequest } from '../Utils';

export default function CaseMessage(props) {
    const [collapsed, toggleCollapsed] = useCollapsable(!props.defaultOpened);
    const [attachments, setAttachments] = React.useState([]);
    // TODO: useRef since this isn't rendered
    const [attachmentRefreshCount, setAttachmentRefreshCount] = React.useState(0);

    const updateAttachments = () => {
        setAttachmentRefreshCount(attachmentRefreshCount + 1);
    };

    React.useEffect(() => {
        const request = new CancelableRequest($.get('/api/attachment/message/' + props.message.ID))
            .then(response => {

                // If this is the first message on the case, and there are no attachments,
                // double check NetSuite to see if there were any missed. This happens
                // occasionally when BR's script that adds an attachment to the case
                // runs after the scheduled script that sends case info to TST
                if (props.index === 0
              && response.length === 0
              && props.selectedCaseNetSuiteId
              && props.message.NetSuiteID
              && attachmentRefreshCount === 0
                && !props.conversationSid) {
                    $.get('/api/attachment/updateAttachments/' + props.selectedCaseNetSuiteId + '/' + props.message.NetSuiteID)
                        .done(() => {
                            updateAttachments();
                        })
                        .fail((error) => {
                            console.error('Unable to update attachments: ' + error);
                        });
                } else {
                    setAttachments(response);
                }
            }, error => {
                console.error('Could not retrieve attachments: '
              + JSON.stringify(error));
            });
        return () => request.cancel();
    }, [props.message.ID, props.selectedCaseNetSuiteId, attachmentRefreshCount]);

    const containerStyles = {};

    if (props.displayBorderBottom) {
        containerStyles['borderBottom'] = '1px solid lightgray';
    }

    const messagePreviewHandler = (event) => {
        toggleCollapsed();
        props.updateOpenedMessages(event.currentTarget.id);
    };

    const messageContents = collapsed ?
        <MessagePreview message={props.message}/>
        : <ExpandedMessage
            message={props.message}
            attachmentList={attachments}
            selectedCaseNetSuiteId={props.selectedCaseNetSuiteId}
            environment={props.environment}
            index={props.index}
        />;

    return (
        <div id="messageContainer" style={containerStyles}>
            <div className="individualMessage" id={props.message.ID} onClick={messagePreviewHandler}>
                {renderFlags(props.message.FormattedTimeSent, props.message.InternalMessage, attachments, props.message.MediaSIDList)}
                <div className="senderAndTimeContainer">
                    <SenderIcon
                        senderName={props.message.SenderFirstName}
                        useMessageStyles={true}
                        internalOnly={props.message.InternalMessage}
                        isCustomerMessage={props.message.SenderType === 'CUSTOMER'}
                    />
                    <h5 id="messagePreviewName">{props.message.SenderFirstName} {props.message.SenderLastName}</h5>

                </div>
                {messageContents}
            </div>
        </div>
    );
}

const renderFlags = (timeSent, internalOnly, attachments, mediaSIDList) => {
    const internalOnlyFlag = internalOnly ?
        <h5 className="internalOnlyHeader"><b>INTERNAL ONLY</b></h5> : null;

    const attachmentFlag = attachments.length || mediaSIDList !== '' ?
        <FontAwesomeIcon className="attachmentIcon" icon={faImage} /> : null;

    return (
        <div className="flagContainer">
            <p id="timeSent">{formatDate(timeSent)}</p>
            {attachmentFlag}
            {internalOnlyFlag}
        </div>
    );
};
