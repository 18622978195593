import React from 'react';
import './css/EmailTemplates.scss';
import $ from 'jquery';

export default function EmailTemplates({
    emailTemplates,
    setEmailTemplates
}) {
    const [addTemplate, setAddTemplate] = React.useState(false);

    React.useEffect(() => {
        setAddTemplate(false);
    }, [emailTemplates]);

    return (
        <table id="emailTemplates">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Template</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {emailTemplates.map(template => <EmailTemplate key={template.ID} emailTemplate={template} setEmailTemplates={setEmailTemplates}/>)}
                {addTemplate ?
                    <EmailTemplate
                        emailTemplate={{ Title: '', Template: '' }}
                        setEmailTemplates={setEmailTemplates}
                        defaultEdit={true}
                    />
                    : null}
            </tbody>
            {addTemplate ? null : <button onClick={() => setAddTemplate(true)}>Add Template</button>}
        </table>
    );
}

function EmailTemplate({
    emailTemplate,
    setEmailTemplates,
    defaultEdit
}) {
    const [edit, setEdit] = React.useState(defaultEdit);
    const [title, setTitle] = React.useState(emailTemplate.Title);
    const [template, setTemplate] = React.useState(emailTemplate.Template);

    const onTitleChange = e => {
        setTitle(e.target.value);
    };

    const onTemplateChange = e => {
        setTemplate(e.target.value);
    };

    const onCancel = () => {
        setEdit(false);
        setTitle(emailTemplate.Title);
        setTemplate(emailTemplate.Template);
    };

    const onDelete = () => {
        if (window.confirm('Are you sure you want to delete this email template?')) {
            $.ajax({
                url: '/api/user/emailTemplates/' + emailTemplate.ID,
                method: 'DELETE',
            })
                .done(templates => {
                    setEdit(false);
                    setEmailTemplates(templates);
                })
                .fail(error => {
                    console.error(error);
                });
        }
    };

    const onSave = () => {
        if (emailTemplate.ID) {
            $.ajax({
                url: '/api/user/emailTemplates/' + emailTemplate.ID,
                method: 'PUT',
                data: {
                    title,
                    template
                }
            })
                .done(templates => {
                    setEdit(false);
                    setEmailTemplates(templates);
                })
                .fail(error => {
                    console.error(error);
                });
        } else {
            $.ajax({
                url: '/api/user/emailTemplates',
                method: 'POST',
                data: {
                    title,
                    template
                }
            })
                .done(templates => {
                    setEmailTemplates(templates);
                    setEdit(false);
                })
                .fail(error => {
                    console.error(error);
                });
        }
    };
    if (edit) {
        return (
            <tr>
                <td><input onChange={onTitleChange} value={title}/></td>
                <td><textarea onChange={onTemplateChange} value={template}/></td>
                <td>
                    <button onClick={onSave}>Save</button>
                    {emailTemplate.ID ? <button onClick={onDelete}>Delete</button> : null}
                    <button onClick={onCancel}>Cancel</button>
                </td>
            </tr>
        );
    }
    return (
        <tr>
            <td>{emailTemplate.Title}</td>
            <td><pre>{emailTemplate.Template}</pre></td>
            <td><button onClick={() => setEdit(true)}>Edit</button></td>
        </tr>
    );
}
