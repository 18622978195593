export default function formatDate(date) {
    const today = new Date();
    const sentDate = new Date(date);

    if (!sentDate.toJSON()) {
        return 'Unknown time';
    }

    // If the time is today, just display the date
    if (sameDay(today, sentDate)) {
        const hoursOffset = today.getHours() - sentDate.getHours();
        if (hoursOffset !== 0) {
            const hoursAgoText = hoursOffset === 1 ? ' Hour Ago' : ' Hours Ago';
            return today.getHours() - sentDate.getHours() + hoursAgoText;
        }

        const minutesOffset = today.getMinutes() - sentDate.getMinutes();
        if (minutesOffset !== 0) {
            const minutesAgoText = minutesOffset === 1 ? ' Minute Ago' : ' Minutes Ago';
            return minutesOffset + minutesAgoText;
        }

        return 'Now';
    }

    for (let i = 1; i < 11; i++) {
        const dayWithOffset = new Date();
        dayWithOffset.setDate(today.getDate() - i);
        if (sameDay(dayWithOffset, sentDate)) {
            return i === 1 ? 'Yesterday' : i + ' Days Ago';
        }
    }

    const month = sentDate.toLocaleString('default', { month: 'long' });
    return month + ' ' + sentDate.getDate();
}

export function sameDay(day1, day2) {
    return day1.getDate() === day2.getDate()
      && day1.getMonth() === day2.getMonth()
      && day1.getFullYear() === day2.getFullYear();
}