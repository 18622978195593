import React from 'react';
import _ from 'lodash';

export default function useThrottledEffect(effect, delay, dependencies) {
    const callback = React.useRef(effect);
    // Ensures the most recent effect handler is being used
    callback.current = effect;
    const throttled = React.useRef(_.throttle(() => callback.current(), delay));
    React.useEffect(throttled.current, dependencies);
}
