import React from 'react';
import '../css/SenderIcon.css';

export default function SenderIcon(props) {
    const senderName = props.senderName || '';
    const upperCaseInitial = senderName.substring(0, 1).toUpperCase();

    const styles = props.useMessageStyles && !props.isCustomerMessage
        ? getEmployeeStyles() : getCustomerIconStyles(senderName);

    const id = props.id ? props.id : 'senderIcon';

    return <h3 style={styles} className={props.useMessageStyles ? 'senderIcon messageSenderIcon' : 'senderIcon'} id={id}>{upperCaseInitial}</h3>;
}

const getCustomerIconStyles = (senderName) => {
    return {
        backgroundColor: nameToBackground(senderName),
        color: '#FFFFFF'
    };
};

const getEmployeeStyles = () => {
    return {
        backgroundColor: '#000000',
        color: '#FFFFFF'
    };
};

const nameToBackground = (nameToHash) => {
    const lowerCaseName = nameToHash.toLowerCase();
    let total = 0;
    for (const index in lowerCaseName) {
        total += lowerCaseName.charCodeAt(index) - 97;
    }
    return backgroundColors[total % 8];
};

const backgroundColors = [
    '#990000',
    '#CC0000',
    '#D56464',
    '#CCCA14',
    '#807D00',
    '#9FCDEA',
    '#1485CC',
    '#065180'
];
