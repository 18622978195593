import React from 'react';
import DOMPurify from 'dompurify';

export default function MessagePreview(props) {
    const messageText = props.message.MessageText && props.message.MessageText.replace(
        "<BASE href='https://3426199.app.netsuite.com/'>", '');

    const cleanHtml = DOMPurify.sanitize(messageText);

    return (
        <div className="messagePreviewText" dangerouslySetInnerHTML={{__html: cleanHtml}} />
    );
}
