import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

export default function Select(props) {
    return (
        <span className="tstSelect">
            <select {...props} />
            <FontAwesomeIcon id="dropDownIcon" icon={faCaretDown} />
        </span>
    );
}
